import React, { useEffect, useState } from "react";

import { PieChart, Pie, Cell, Tooltip } from "recharts";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from "recharts";

import DashboardLayout from "../../layouts/DashboardLayout";
import { FaArrowAltCircleRight } from "react-icons/fa";

import Icon1 from "../../../src/assets/DashboardIcon/purchase.png";
import Icon2 from "../../../src/assets/DashboardIcon/sales.png";
import Icon3 from "../../../src/assets/DashboardIcon/salesamount.png";
import Icon4 from "../../../src/assets/DashboardIcon/expenseamount.png";
import Icon5 from "../../../src/assets/DashboardIcon/totalpurchase.png";
import Icon6 from "../../../src/assets/DashboardIcon/payment.png";
import Icon7 from "../../../src/assets/DashboardIcon/totalsales.png";
import Icon8 from "../../../src/assets/DashboardIcon/totalexpense.png";
import ExpiredItemsTable from "./ExpiredItemsTable";
import StockAlert from "./StockAlert";
import DateExpiredMedicine from "../../components/Modal/DateExpireMedicine";
import OutOfStockMedicine from "../../components/Modal/OutOfStockMedicine";

const Dashboard = () => {
  useEffect(() => {
    document.title = "Home";
  }, []);
  // data for purchase and sales bar chart
  const Linedata = [
    { month: "JAN", purchase: 15000, sales: 4000 },
    { month: "FEB", purchase: 0, sales: 0 },
    { month: "MAR", purchase: 0, sales: 0 },
    { month: "APR", purchase: 0, sales: 0 },
    { month: "MAY", purchase: 0, sales: 0 },
    { month: "JUN", purchase: 30000, sales: 15000 },
    { month: "JUL", purchase: 15000, sales: 8000 },
    { month: "AUG", purchase: 0, sales: 0 },
    { month: "SEP", purchase: 0, sales: 0 },
    { month: "OCT", purchase: 0, sales: 0 },
    { month: "NOV", purchase: 0, sales: 0 },
    { month: "DEC", purchase: 0, sales: 0 },
  ];

  // circle data chart
  const Circledata = [
    { name: "Income", value: 75 },
    { name: "Expense", value: 25 },
  ];

  const COLORS = ["rgba(36, 104, 239, 0.9)", "rgba(50, 205, 255, 0.9)"];
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize="14px"
      >
        {(percent * 100).toFixed(0)}%
      </text>
    );
  };
  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <ul className="flex justify-center gap-4 list-none mt-5">
        {payload.map((entry, index) => (
          <li key={`item-${index}`} className="flex items-center">
            <span
              style={{
                display: "inline-block",
                width: "30px",
                height: "12px",
                borderRadius: "8px 0 8px 0",
                backgroundColor: entry.color,
                marginRight: "5px",
              }}
            ></span>
            {/* Display the name like 'Income' or 'Expense' */}
            <span className="text-sm">{entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  // Data for the pie chart
  const renderLabel = (entry) => `${entry.name} ${entry.value}%`;
  const data = [
    { name: "Napa", value: 30, color: "rgba(36, 104, 239, 0.9)" }, // Blue
    { name: "Sergel", value: 25, color: "rgba(255, 99, 71, 0.9)" }, // Red
    { name: "Pantonix", value: 20, color: "rgba(50, 205, 50, 0.9)" }, // Green
    { name: "Exium", value: 15, color: "rgba(255, 215, 0, 0.9)" }, // Gold
    { name: "Mixtard-30", value: 10, color: "rgba(138, 43, 226, 0.9)" }, // Purple
    { name: "Paracetamol", value: 30, color: "rgba(255, 69, 0, 0.9)" }, // OrangeRed
    { name: "Ibuprofen", value: 25, color: "rgba(64, 224, 208, 0.9)" }, // Turquoise
    { name: "Aspirin", value: 20, color: "rgba(255, 20, 147, 0.9)" }, // DeepPink
    { name: "Cough Syrup", value: 15, color: "rgba(75, 0, 130, 0.9)" }, // Indigo
    { name: "Vitamin C", value: 10, color: "rgba(160, 82, 45, 0.9)" }, // Sienna
  ];

  return (
    <DashboardLayout className="relative">
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols gap-2">
        <div className="flex items-center gap-1 p-2 bg-[#C7F2FF] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#67DBFF] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon1} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1 text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Total Purchase Due</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 p-2 bg-[#FDD4DD] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#FE92AA] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon2} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1 text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Total Sales Due</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 p-2 bg-[#CDFFCE] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#62FF65] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon3} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1 text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Total Sales Amount</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 p-2 bg-[#FEE28E] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#F4BE1A] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon4} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1 text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Total Expense Amount</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 p-2 bg-[#EBC0FF] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#D37BFC] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon5} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1 text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Today's Total Purchase</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 p-2 bg-[#CDCFFD] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#8287FF] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon6} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1  text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Today's Payment Received</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 p-2 bg-[#FDDECD] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#FCAC80] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon7} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1 text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Today's Total Sales</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 p-2 bg-[#BCFDE6] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#21D998] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon8} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1 text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Today's Total Expense</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5">
        <div className="lg:flex items-center gap-1 p-3 bg-gray-200 text-primary rounded-tl-3xl rounded-br-3xl rounded ">
          <div>
            <p className=" py-2 px-6 bg-primary text-white rounded-tl-2xl rounded-br-2xl rounded cursor-pointer text-center lg:mb-0 mb-2">
              Others
            </p>
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols gap-2 w-full">
            <div className="text-primary text-center leading-3  border-r border-blue-600">
              <p className="text-lg font-bold">86</p>
              <p className="text-sm font-semibold">Customers</p>
            </div>
            <div className="text-primary text-center leading-3  border-r border-blue-600">
              <p className="text-lg font-bold">46</p>
              <p className="text-sm font-semibold">Suppliers</p>
            </div>
            <div className="text-primary text-center leading-3  border-r border-blue-600">
              <p className="text-lg font-bold">861</p>
              <p className="text-sm font-semibold">Purchase Invoice</p>
            </div>
            <div className="text-primary text-center leading-3  lg:boder-none border-r border-blue-600">
              <p className="text-lg font-bold">336</p>
              <p className="text-sm font-semibold">Sales Invoice</p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 grid-cols gap-3 mb-5">
        <div
          style={{ width: "100%", height: "280px" }}
          className="lg:col-span-3 flex justify-center  p-3 bg-white custom-shadow rounded-tl-3xl rounded-br-3xl rounded w-full"
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={Linedata}
              margin={{
                top: 10,
                right: 0,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" tick={{ fontSize: 12 }} />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />{" "}
              <Bar
                dataKey="purchase"
                name="Purchase"
                fill="rgba(36, 104, 239, 0.9)"
              />{" "}
              <Bar
                dataKey="sales"
                name="Sales"
                fill="rgba(50, 205, 255, 0.9)"
              />{" "}
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className=" p-3 bg-white custom-shadow rounded-tl-3xl rounded-br-3xl rounded w-[100%]">
          <p className="text-center text-primary font-semibold">
            Income Expense Statement{" "}
          </p>
          <div className="flex justify-center items-center py-5 w-full">
            <PieChart width={180} height={180}>
              <Pie
                data={Circledata}
                cx={85}
                cy={80}
                innerRadius={0}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={1}
                dataKey="value"
                labelLine={false}
                label={renderCustomizedLabel}
                cornerRadius={5}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="white"
                    strokeWidth={2}
                  />
                ))}
              </Pie>
              <Legend
                content={renderLegend}
                verticalAlign="bottom"
                height={10}
              />
            </PieChart>
          </div>
        </div>
      </div>
      <div className="grid lg:grid-cols-4 grid-cols gap-3 mb-5">
        <div className="lg:col-span-3 flex justify-center  p-3 bg-white custom-shadow rounded-tl-3xl rounded-br-3xl rounded w-full">
          <ExpiredItemsTable />
        </div>
        <div className="flex justify-center p-3 bg-white custom-shadow rounded-tl-3xl rounded-br-3xl rounded w-full">
          <StockAlert />
        </div>
      </div>
      <div className="flex justify-center  p-3 bg-gray-200 rounded-tl-3xl rounded-br-3xl rounded w-full">
        <div className="text-center mt-5">
          <h1 className="-mb-[4rem] text-xl font-semibold text-primary">
            Top 10 Trending Items %
          </h1>
          <PieChart width={500} height={500} className="-mb-[4rem]">
            <circle
              cx="50%" // Center X (400 width / 2)
              cy="50%" // Center Y (400 height / 2)
              r="125" // Radius to match slightly bigger than outerRadius
              fill="#FFFFFF" // White color for the background
            />
            <Pie
              data={data}
              cx="50%" // Center X-axis
              cy="50%" // Center Y-axis
              innerRadius={60} // Adjust for doughnut shape
              outerRadius={120}
              dataKey="value"
              label={renderLabel} // Custom label function
              cornerRadius={10} // Add 10px border radius
              stroke="#FFFFFF" // Set the stroke to white to simulate the gap
              strokeWidth={2}
              backGroundColor="#ffff" // 2px gap between the cells
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </div>
      </div>

      <div className="absolute left-[27%] top-[16%] ">
        <div className="grid grid-cols-2 gap-5 items-start ">
          <DateExpiredMedicine />
          <OutOfStockMedicine />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
