import React, { useEffect, useState } from "react";
import { IoIosCloseCircle } from "react-icons/io";

const DateExpiredMedicine = () => {
  const [isVisible, setIsVisible] = useState(true);

  const expiredItems = [
    {
      name: "Vitamin - C",
      batch: "DXD069",
      expireDate: "30.05.2024",
    },
    {
      name: "Lan 500mg",
      batch: "DXD069",
      expireDate: "30.05.2024",
    },
    {
      name: "Paracetamol 500mg",
      batch: "DXD069",
      expireDate: "30.05.2024",
    },
    {
      name: "Amoxicillin and Clavulanate",
      batch: "DXD069",
      expireDate: "30.05.2024",
    },
    {
      name: "Vitamin - C",
      batch: "DXD069",
      expireDate: "30.05.2024",
    },
    {
      name: "Lan 500mg",
      batch: "DXD069",
      expireDate: "30.05.2024",
    },
    {
      name: "Paracetamol 500mg",
      batch: "DXD069",
      expireDate: "30.05.2024",
    },
    {
      name: "Amoxicillin and Clavulanate",
      batch: "DXD069",
      expireDate: "30.05.2024",
    },
  ];

  useEffect(() => {
    const isPopupClosed = localStorage.getItem("dateExpiredPopupClosed");
    if (isPopupClosed === "true") {
      setIsVisible(false);
    }
  }, []);

  const closePopup = () => {
    setIsVisible(false);
    localStorage.setItem("dateExpiredPopupClosed", "true"); // Store in local storage when closed
  };

  if (!isVisible) return null;

  return (
    <div className="relative p-5 bg-white custom-shadow z-10 rounded-tl-3xl rounded-br-3xl rounded w-full ">
      <div
        className="absolute right-2 top-2 text-2xl cursor-pointer text-primary hover:text-secondary"
        onClick={closePopup}
      >
        <IoIosCloseCircle />
      </div>
      <h1 className=" text-lg items-center gap-5 text-center pb-2 text-primary font-semibold">
        Date Expired Medicine{" "}
      </h1>
      <table className="min-w-full bg-white border-collapse">
        <thead>
          <tr className="text-left text-sm text-primary  bg-gray-200 ">
            <th className="p-3 border-b-2">Medicine Name</th>
            <th className="p-3 border-b-2">Batch No</th>
            <th className="p-3 border-b-2">Expire Date</th>
          </tr>
        </thead>
        <tbody>
          {expiredItems.map((item, index) => (
            <tr key={index} className="text-sm border-b">
              <td className="p-3">{item.name}</td>
              <td className="p-3">{item.batch}</td>
              <td className="p-3">{item.expireDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DateExpiredMedicine;
