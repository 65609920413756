import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { FaBars } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { addCustomer, fetchCustomers } from "../../api/api"; // Assuming addCustomer is the correct API function
import Swal from "sweetalert2";

const NewCustomer = () => {
  useEffect(() => {
    document.title = "New Customer";
  }, []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null); // To handle any API errors
  const [successMessage, setSuccessMessage] = useState(null); // To show success messages
  const [formErrors, setFormErrors] = useState({}); // State for form validation errors
  const [nextCustomerID, setNextCustomerID] = useState("");

  // State for form inputs
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    mobile: "",
    email: "",
    previous_due: 0,
    address: "",
  });

  const fetchAndSetNextCustomerID = async () => {
    try {
      const customers = await fetchCustomers(); // Fetch the customer list
      console.log("Fetched customers:", customers); // Log the customers

      if (customers && customers.length > 0) {
        const lastCustomer = customers[customers.length - 1];
        const lastCustomerCode =
          lastCustomer.customer_code || `CU${lastCustomer.id}`; // Use customer ID directly if customer_code is not present

        console.log("Last customer code (fallback if null):", lastCustomerCode); // Log the fallback code

        const numericPart = parseInt(lastCustomerCode.slice(2), 10); // Parse the numeric part after "CU"
        const newID = `CU${numericPart + 1}`; // Generate new ID without leading zeros
        console.log("Next customer ID:", newID); // Log the generated ID
        setNextCustomerID(newID); // Set the next customer ID
      } else {
        setNextCustomerID("CU1"); // Default ID if no customers found (without leading zeros)
      }
    } catch (err) {
      console.error("Error fetching customers:", err);
    }
  };

  useEffect(() => {
    fetchAndSetNextCustomerID();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Validate form inputs
  const validateForm = () => {
    const errors = {};
    // Required fields validation
    if (!formData.name.trim()) {
      errors.name = "Customer name is required.";
    }
    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile number is required.";
    } else if (!/^01[2-9]\d{8}$/.test(formData.mobile)) {
      errors.mobile = "Mobile number must be 11 digits and start with 01.";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is not valid.";
    }
    // No validation for optional fields (previous_due and address)
    return errors;
  };

  const handleCustomerSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);

    // Validate form inputs
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setIsSubmitting(false);
      return;
    }

    try {
      // Make API call to add customer
      const response = await addCustomer({ ...formData, id: nextCustomerID });
      Swal.fire("Success", "Customer added successfully", "success");
      setSuccessMessage("Customer added successfully!");

      // Reset form data
      setFormData({
        name: "",
        mobile: "",
        email: "",
        previous_due: 0,
        address: "",
      });
      setFormErrors({});
      setNextCustomerID(""); // Reset customer ID before generating the next one
      // Update customer list by placing the new customer at the top

      // Generate the next customer ID
      await fetchAndSetNextCustomerID(); // Trigger the next customer ID generation without refreshing
    } catch (err) {
      // Check for validation errors from the API
      if (err.response && err.response.status === 401) {
        const errors = err.response.data.errors; // Laravel validation errors
        setFormErrors(errors); // Set form errors state for rendering

        // Display appropriate error messages in SweetAlert
        const errorMessages = [];
        if (errors.email) {
          errorMessages.push(
            "Email is already used, please try another email."
          );
        }
        if (errors.mobile) {
          errorMessages.push(
            "Mobile number is already used, please try another mobile number."
          );
        }

        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: errorMessages.join(" "),
        });
      } else {
        // Handle other types of errors
        Swal.fire(
          "Error",
          "Failed to add customer. Please try again.",
          "error"
        );
        setError("Failed to add customer. Please try again.");
        console.error("Error adding customer:", err);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="mb-5 flex justify-between items-center">
          <h2 className="text-lg font-semibold text-primary ">Add Customer</h2>
          <div>
            <Link to="/customerlist">
              <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
                <span>
                  <FaBars />
                </span>
                Customer List
              </p>
            </Link>
          </div>
        </div>
        <form
          className="grid grid-cols-2 gap-5"
          onSubmit={handleCustomerSubmit}
        >
          <div className="col-span-2 w-24 flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Customer ID
            </label>
            <input
              type="text"
              name="id"
              value={nextCustomerID || "Generating..."}
              className="px-2 py-1.5 border  bg-gray-300 rounded-lg  focus:outline-none"
              readOnly
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Customer Name*
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="capitalize px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
              // required
            />
            {formErrors.name && (
              <p className="text-red-500 text-sm -mb-1">{formErrors.name}</p>
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Mobile No*
            </label>
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              placeholder="01xxxxxxxxx"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
              maxLength="11"
            />

            {formErrors.mobile && (
              <p className="text-red-500 text-sm">
                {Array.isArray(formErrors.mobile)
                  ? formErrors.mobile.join(", ")
                  : formErrors.mobile}
              </p> // Display mobile errors
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Email Address*
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
              // required
            />

            {formErrors.email && (
              <p className="text-red-500 text-sm">
                {Array.isArray(formErrors.email)
                  ? formErrors.email.join(", ")
                  : formErrors.email}
              </p> // Display email errors
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Previous Due
            </label>
            <input
              type="number"
              name="previous_due"
              value={formData.previous_due}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Address</label>
            <textarea
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none h-32"
            />
          </div>

          <div className="col-span-full flex justify-center w-full">
            <button
              type="submit"
              className="w-60 mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default NewCustomer;
