// import React from "react";
// import DashboardLayout from "../../layouts/DashboardLayout";
// import ReusableForm from "../../components/ReusableForm";
// import { FaBars } from "react-icons/fa6";
// import { Link } from "react-router-dom";

// const EditCustomer = () => {
//   const customerFields = [
//     {
//       label: "Customer Name*",
//       name: "customerName",
//       type: "text",
//       placeholder: "Enter Here",
//       required: true,
//     },
//     {
//       label: "Mobile No*",
//       name: "mobileNo",
//       type: "number",
//       placeholder: "Enter Here",
//       required: true,
//     },
//     {
//       label: "Email Address*",
//       name: "email",
//       type: "email",
//       placeholder: "Enter Here",
//       required: true,
//     },
//     {
//       label: "Previous Due",
//       name: "previousDue",
//       type: "number",
//       placeholder: "Enter Here",
//       required: false,
//     },
//     {
//       label: "Address",
//       name: "address",
//       type: "textarea",
//       placeholder: "Enter Here",
//       required: false,
//     },
//   ];

//   const handleCustomerSubmit = (data) => {
//     console.log("Customer Data:", data);
//     // Process customer data (e.g., send to server or state management)
//   };
//   return (
//     <DashboardLayout>
//       <div className="p-4 bg-white custom-shadow custom-border w-full">
//         <div className="mb-5 flex justify-between items-center">
//           <h2 className="text-lg font-semibold text-primary ">Edit Customer</h2>
//           <div>
//             <Link to="/customerlist">
//               <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
//                 {" "}
//                 <span>
//                   <FaBars />
//                 </span>
//                 Customer List
//               </p>
//             </Link>
//           </div>
//         </div>
//         <ReusableForm
//           formFields={customerFields}
//           onSubmit={handleCustomerSubmit}
//         />
//       </div>
//     </DashboardLayout>
//   );
// };

// export default EditCustomer;

import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { FaBars } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { editCustomer } from "../../api/api";
import Swal from "sweetalert2";

const EditCustomer = () => {
  useEffect(() => {
    document.title = "Edit Customer";
  }, []);
  const location = useLocation();
  const navigate = useNavigate();
  const { customer } = location.state || {};

  const [formErrors, setFormErrors] = useState({}); //

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    previous_due: "", // Initialize as string to prevent trim errors
    address: "",
  });

  useEffect(() => {
    if (customer) {
      setFormData({
        name: customer.name || "",
        mobile: customer.mobile || "",
        email: customer.email || "",
        previous_due: customer.previous_due?.toString() || "", // Convert to string
        address: customer.address || "",
      });
    }
  }, [customer]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};
    // Required fields validation

    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile number is required.";
    } else if (!/^01[2-9]\d{8}$/.test(formData.mobile)) {
      errors.mobile = "Mobile number must be 11 digits and start with 01.";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is not valid.";
    }
    // No validation for optional fields (previous_due and address)
    return errors;
  };
  const handleCustomerSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Stop the submission if there are errors
    }
    try {
      await editCustomer(customer.id, formData);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Customer updated successfully!",
      });
      navigate("/customerlist");
    } catch (error) {
      console.error("Failed to edit customer:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update customer. Please try again.",
      });
    }
  };

  return (
    <DashboardLayout>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="mb-5 flex justify-between items-center">
          <h2 className="text-lg font-semibold text-primary ">Edit Customer</h2>
          <div>
            <Link to="/customerlist">
              <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
                <span>
                  <FaBars />
                </span>
                Customer List
              </p>
            </Link>
          </div>
        </div>
        <form
          className="grid grid-cols-2 gap-5"
          onSubmit={handleCustomerSubmit}
        >
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Customer Name
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter Here"
              required // Marking as required
              className="capitalize px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Mobile No*
            </label>
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              placeholder="01xxxxxxxxx"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
              maxLength="11"
            />
            {formErrors.mobile && (
              <p className="text-red-500 text-sm">
                {Array.isArray(formErrors.mobile)
                  ? formErrors.mobile.join(", ")
                  : formErrors.mobile}
              </p> // Display mobile errors
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Email Address*
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
            />
            {formErrors.email && (
              <p className="text-red-500 text-sm">
                {Array.isArray(formErrors.email)
                  ? formErrors.email.join(", ")
                  : formErrors.email}
              </p> // Display email errors
            )}
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Previous Due
            </label>
            <input
              type="number"
              name="previous_due"
              value={formData.previous_due}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Address</label>
            <textarea
              name="address"
              value={formData.address}
              onChange={handleInputChange}
              placeholder="Enter Here"
              required // Marking as required
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none h-32"
            />
          </div>

          <div className="col-span-full flex justify-center w-full">
            <button
              type="submit"
              className=" w-60 mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
};

export default EditCustomer;
