import axios from "axios";

// Set up the base URL for your Laravel API
const API_BASE_URL = "https://api-pharmacy.opt2cash.com/api/v1";

// Create an Axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true, // Required for Laravel Sanctum (if using cookies)
});

// Attach Authorization token to every request
api.interceptors.request.use((config) => {
  const token = localStorage.getItem("authToken"); // Get token from localStorage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Centralized error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API Error:", error.response ? error.response.data : error);
    return Promise.reject(error); // Reject the promise to propagate errors
  }
);

export const registerUser = async (userData) => {
  try {
    const response = await api.post("/register", userData);
    return response.data;
  } catch (error) {
    console.error("Error registering user:", error.response?.data || error);
    throw error;
  }
};

export const loginUser = async (loginData) => {
  try {
    const response = await api.post("/login", loginData);
    // Store token in localStorage upon successful login
    if (response.data.token) {
      localStorage.setItem("authToken", response.data.token);
    }
    return response.data;
  } catch (error) {
    console.error("Error logging in:", error.response?.data || error);
    throw error;
  }
};

export const logoutUser = () => {
  localStorage.removeItem("authToken"); // Remove token from localStorage
  // You may also want to make an API call to log out on the server
};
export const fetchCustomers = async () => {
  try {
    const response = await api.get("/cus_list");
    return response.data;
  } catch (error) {
    console.error("Error fetching customers:", error.response?.data || error);
    throw error;
  }
};

export const addCustomer = async (customerData) => {
  try {
    const response = await api.post("/cus_new", customerData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding customer:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const editCustomer = async (customerId, updatedCustomerData) => {
  try {
    const response = await api.put(
      `/cus_upd/${customerId}`,
      updatedCustomerData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error editing customer:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const deleteCustomer = async (customerId) => {
  try {
    const response = await api.delete(`/cus_del/${customerId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting customer:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

/**
 * Fetch all supplier
 */
export const fetchSuppliers = async () => {
  try {
    const response = await api.get("/sup_list");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching suppliers:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const addSupplier = async (supplierData) => {
  try {
    const response = await api.post("/sup_new", supplierData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding supplier:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const editSupplier = async (supplierId, updatedSupplierData) => {
  try {
    const response = await api.put(
      `/sup_upd/${supplierId}`,
      updatedSupplierData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error editing supplier:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const deleteSupplier = async (supplierId) => {
  try {
    const response = await api.delete(`/sup_del/${supplierId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting supplier:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

//medicine section
export const fetchMedicines = async () => {
  try {
    const response = await api.get("/med_list"); // Updated endpoint
    return response.data; // Return the data received from the API
  } catch (error) {
    console.error(
      "Error fetching medicines:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const fetchMedicinesBySupplier = async (supplierId) => {
  try {
    const response = await api.get(`/med_list/${supplierId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching medicines:", error.response?.data || error);
    throw error;
  }
};

export const addMedicine = async (medicineData) => {
  try {
    const response = await api.post(`/med_new`, medicineData, {
      headers: {
        //  "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding medicine:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const editMedicine = async (medicineId, updatedMedicineData) => {
  try {
    // Log each key-value pair in FormData
    for (let [key, value] of updatedMedicineData.entries()) {
      console.log(key, value);
    }

    const response = await api.post(
      `/med_upd/${medicineId}`,
      updatedMedicineData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error editing medicine:",
      error.response ? error.response.data : error
    );
    throw new Error(
      error.response?.data?.message ||
        "Failed to update medicine. Please try again."
    );
  }
};

export const deleteMedicine = async (medicineId) => {
  try {
    const response = await api.delete(`/med_del/${medicineId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting medicine:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// unit section
export const fetchUnits = async () => {
  try {
    const response = await api.get("/unit_list"); // Updated endpoint
    return response.data; // Return the data received from the API
  } catch (error) {
    console.error(
      "Error fetching unit:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const addUnit = async (unitData) => {
  try {
    const response = await api.post(`/unit_new`, unitData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding unit:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const editUnit = async (unitId, updatedUnitData) => {
  try {
    const response = await api.put(`/unit_upd/${unitId}`, updatedUnitData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error editing unit:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const deleteUnit = async (unitId) => {
  try {
    const response = await api.delete(`/unit_del/${unitId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting unit:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// type section
export const fetchTypes = async () => {
  try {
    const response = await api.get(`/med_type_list`); // Updated endpoint
    return response.data; // Return the data received from the API
  } catch (error) {
    console.error(
      "Error fetching types:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const addType = async (typeData) => {
  try {
    const response = await api.post(`/med_type_new`, typeData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding type:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const editType = async (typeId, updatedTypeData) => {
  try {
    const response = await api.put(`/med_type_upd/${typeId}`, updatedTypeData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error editing type:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const deleteType = async (typeId) => {
  try {
    const response = await api.delete(`/med_type_del/${typeId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting type:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// leaf setting
export const fetchLeafs = async () => {
  try {
    const response = await api.get(`/leaf_list`); // Updated endpoint
    return response.data; // Return the data received from the API
  } catch (error) {
    console.error(
      "Error fetching leafs:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const addLeaf = async (leafData) => {
  try {
    const response = await api.post(`/leaf_new`, leafData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding leaf:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const editLeaf = async (leafId, updatedLeafData) => {
  try {
    const response = await api.put(`/leaf_upd/${leafId}`, updatedLeafData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error editing leaf:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const deleteLeaf = async (leafId) => {
  try {
    const response = await api.delete(`/leaf_del/${leafId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting leaf:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// Fetch purchase items for a particular purchase
export const fetchPurchases = async () => {
  try {
    const response = await api.get(`/pur_list`); // Updated endpoint
    return response.data; // Return the data received from the API
  } catch (error) {
    console.error(
      "Error fetching leafs:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};
export const addPurchase = async (purchaseData) => {
  try {
    const response = await api.post(`/pur_new`, purchaseData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error adding purchase:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};
export const editPurchase = async (purchaseId, purchaseData) => {
  try {
    const response = await api.put(`/pur_upd/${purchaseId}`, purchaseData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error editing purchase:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};

export const deletePurchase = async (purchaseId) => {
  try {
    const response = await api.delete(`/pur_del/${purchaseId}`);
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting purchase:",
      error.response ? error.response.data : error
    );
    throw error;
  }
};
