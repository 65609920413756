import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import Loader from "../../components/Loader";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoIosCopy } from "react-icons/io";
import { FaEdit } from "react-icons/fa";
import { MdReceipt } from "react-icons/md";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaFileExcel } from "react-icons/fa6";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { IoIosPrint } from "react-icons/io";
import { FaFileCsv } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import { HiMiniViewColumns } from "react-icons/hi2";
import { CiSearch } from "react-icons/ci";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import copy from "copy-to-clipboard";
import Swal from "sweetalert2";
import defaultImage from "../../assets/no-image.png";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Icon1 from "../../../src/assets/PurchaseIcon/1.png";
import Icon2 from "../../../src/assets/PurchaseIcon/2.png";
import Icon3 from "../../../src/assets/PurchaseIcon/3.png";
import Icon4 from "../../../src/assets/PurchaseIcon/4.png";
import { fetchPurchases, deletePurchase, fetchSuppliers } from "../../api/api";

const PurchaseList = () => {
  useEffect(() => {
    document.title = "Purchase List";
  }, []);

  const [actionDropdowns, setActionDropdowns] = useState({});
  const [activePurchase, setActivePurchase] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [checkedItems, setCheckedItems] = useState({});
  const [isColumnsDropdownOpen, setIsColumnsDropdownOpen] = useState(false);
  // fetch Purchase
  const [purchases, setPurchases] = useState([]);
  const [suppliers, setSuppliers] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const tableRef = useRef();
  const columnsRef = useRef();

  const navigate = useNavigate(); // Use useNavigate

  const handleViewPurchase = (purchase) => {
    // Navigate to the EditPurchase page and pass the Purchase data
    navigate(`/viewpurchase/${purchase.id}`, { state: { purchase } });
  };

  const handleEditPurchase = (purchase) => {
    // Navigate to the EditPurchase page and pass the Purchase data
    navigate(`/editpurchase/${purchase.id}`, { state: { purchase } });
  };

  const toggleActionDropdown = (purchaseId) => {
    setActionDropdowns((prev) => {
      // Check if the dropdown for the clicked purchase is already open
      const isOpen = prev[purchaseId] || false;
      // Close all dropdowns and open the one that was clicked
      return { [purchaseId]: !isOpen };
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (columnsRef.current && !columnsRef.current.contains(event.target)) {
        setIsColumnsDropdownOpen(false);
      }

      if (!event.target.closest(".action-dropdown")) {
        setActionDropdowns({});
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Fetch purchases
    const fetchPurchasesData = async () => {
      try {
        const response = await fetchPurchases(); // Assuming fetchPurchases is imported
        setPurchases(response.purchases || []);
      } catch (error) {
        console.error("Error fetching purchases:", error);
      }
    };
    const fetchSuppliersData = async () => {
      try {
        const response = await fetchSuppliers(); // Assuming fetchSuppliers is imported and returns data
        if (Array.isArray(response)) {
          setSuppliers(response);
        } else {
          console.error("Suppliers response is not an array:", response);
          setSuppliers([]); // Ensure suppliers is always an array
        }
      } catch (error) {
        console.error("Error fetching suppliers:", error);
        setSuppliers([]); // Fallback to an empty array
      }
    };

    // Load both purchases and suppliers data
    fetchPurchasesData();
    fetchSuppliersData();
  }, []);

  const getSupplierName = (supplierId) => {
    if (!Array.isArray(suppliers)) {
      console.warn("Suppliers is not an array:", suppliers);
      return "Unknown Supplier";
    }

    const supplier = suppliers.find(
      (s) => s.id === supplierId && s.status === 1
    );
    return supplier ? supplier.name : "Inactive Supplier";
  };

  const getPaymentStatus = (paidAmount, grandTotal) => {
    // Convert to numbers to avoid string comparisons
    const paid = parseFloat(paidAmount) || 0;
    const total = parseFloat(grandTotal) || 0;

    // Allow a small tolerance for precision issues
    const tolerance = 0.01;

    if (Math.abs(paid - total) <= tolerance) {
      return "Paid";
    } else if (paid > 0 && paid < total) {
      return "Partial";
    } else {
      return "Unpaid";
    }
  };

  // delete purchase
  const handleDeletePurchase = (purchaseId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Make API call to delete purchase
          await deletePurchase(purchaseId); // Replace with your API function to delete
          setPurchases((prevPurchases) =>
            prevPurchases.filter((purchase) => purchase.id !== purchaseId)
          );
          Swal.fire("Deleted!", "Your purchase has been deleted.", "success");
        } catch (error) {
          Swal.fire(
            "Error!",
            "There was an issue deleting the purchase.",
            "error"
          );
          console.error("Error deleting purchase:", error);
        }
      }
    });
  };

  const calculateTotals = () => {
    let totalAmount = 0;
    let totalPaid = 0;
    let totalDue = 0;

    purchases.forEach((purchase) => {
      totalAmount += parseFloat(purchase.grand_total) || 0; // Convert to number, fallback to 0
      totalPaid += parseFloat(purchase.paid_amount) || 0;
      totalDue += parseFloat(purchase.due_amount) || 0;
    });

    return { totalAmount, totalPaid, totalDue };
  };

  const { totalAmount, totalPaid, totalDue } = calculateTotals();

  return (
    <DashboardLayout>
      <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols gap-2 mb-5">
        <div className="flex items-center gap-1 p-2 bg-[#EBC0FF] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#D37BFC] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon1} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1 text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Total Invoice</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 p-2 bg-[#CDCFFD] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#8287FF] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon2} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1  text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Total Invoice Amount</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 p-2 bg-[#FDDECD] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#FCAC80] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon3} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1 text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Total Paid Amount</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-1 p-2 bg-[#BCFDE6] text-white rounded-tl-3xl rounded-br-3xl rounded ">
          <div className="flex justify-between w-full">
            <div className="flex justify-center items-center p-2 bg-[#21D998] text-white rounded-tl-2xl rounded-br-2xl rounded ">
              <img src={Icon4} alt="" className="w-6 h-6" />
            </div>
            <div className="ms-1 text-primary">
              <p className="text-xl font-bold">$ 234323900</p>
              <p className="text-sm">Total Purchase Due</p>
            </div>
            <div className="flex items-end mx-1">
              <div className="text-primary text-lg cursor-pointer ">
                <FaArrowAltCircleRight />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-primary">Purchase List</h2>
          <div>
            <Link to="/addpurchase">
              <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
                <span>
                  <BsFillPlusCircleFill />
                </span>
                Add Purchase
              </p>
            </Link>
          </div>
        </div>
        {/* Table Controls */}
        <div className="flex justify-between items-center mb-4 dropdown">
          <div className="flex justify-between items-center gap-3 font-semibold text-primary">
            <p>Show</p>
            <select
              className="flex  items-center border custom-border w-24 px-1 py-1 focus:outline-none cursor-pointer"
              value={itemsPerPage}
              // onChange={handleItemsPerPageChange} // Made dynamic
            >
              <option
                value={5}
                className="hover:bg-white cursor-pointer custom-border"
              >
                5
              </option>
              <option
                value={10}
                className="hover:bg-white cursor-pointer custom-border"
              >
                10
              </option>
              <option
                value={15}
                className="hover:bg-white cursor-pointer custom-border"
              >
                15
              </option>
            </select>
          </div>
          {/* <div className="w-24">
            <div className="flex justify-between text-center items-center bg-red-600 custom-border px-2 py-1">
              <button className=" text-white hover:text-gray-300 py-1 px-2 cursor-pointer  flex items-center gap-1">
                Delete{" "}
                <span>
                  <RiDeleteBin6Fill />
                </span>
              </button>
            </div>
          </div> */}

          <div className="flex justify-between text-center items-center bg-primary custom-border px-2 py-1">
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              Copy{" "}
              <span>
                <IoIosCopy />
              </span>
            </button>
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              Excel{" "}
              <span>
                <FaFileExcel />
              </span>
            </button>
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              PDF{" "}
              <span>
                <BsFillFileEarmarkPdfFill />
              </span>
            </button>
            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              Print{" "}
              <span>
                <IoIosPrint />
              </span>
            </button>

            <button className=" text-white hover:text-secondary py-1 px-2 cursor-pointer border-r flex items-center gap-1">
              CSV{" "}
              <span>
                <FaFileCsv />
              </span>
            </button>

            <button
              ref={columnsRef}
              className="relative  text-white  py-1 px-2 cursor-pointer flex items-center gap-1 "
            >
              <span className="hover:text-secondary">Columns</span>
              <span>
                <HiMiniViewColumns />
              </span>
            </button>
          </div>
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="border custom-border bg-gray-300 text-primary font-semibold rounded-md p-2 focus:outline-none pl-8 focus:ring-0 focus:ring-blue-500"
              value={searchTerm}
              // onChange={handleSearch}
            />
            <span className="absolute left-2 top-3 text-xl text-primary font-bold">
              <CiSearch />
            </span>
          </div>
        </div>
        {/* Table */}
        <table
          ref={tableRef}
          className="bg-white border border-gray-200 w-full"
        >
          <thead>
            <tr className="bg-primary text-white text-sm">
              <th className="py-1 px-2 border-r">SL</th>
              <th className="p-1 border-r">Date</th>
              <th className="p-1 border-r">Purchase ID</th>
              <th className="p-1 border-r">Invoice Number</th>
              <th className="p-1 border-r">Supplier Name</th>
              <th className="p-1 border-r">Total Amount</th>
              <th className="p-1 border-r ">Paid</th>
              <th className="p-1 border-r ">Due</th>
              <th className="p-1 border-r ">Payment Status</th>
              <th className="p-1 border-r text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {purchases.length > 0 ? (
              purchases.map((purchase, index) => {
                const paymentStatus = getPaymentStatus(
                  purchase.paid_amount,
                  purchase.grand_total
                );
                const statusBgColor =
                  paymentStatus === "Paid"
                    ? "bg-green-500 text-white"
                    : paymentStatus === "Partial"
                    ? "bg-yellow-400 text-black"
                    : "bg-red-500 text-white";

                return (
                  <tr key={purchase.id} className="border-b">
                    <td className="p-1 border-r text-gray-600 text-center">
                      {index + 1}
                    </td>
                    <td className="p-1 border-r text-gray-600">
                      {purchase.date || "N/A"}
                    </td>
                    <td className="p-1 border-r text-gray-600">{`PUR${purchase.id}`}</td>
                    <td className="p-1 border-r text-gray-600">
                      {`INV${purchase.invoice_no}`}
                    </td>
                    <td className="p-1 border-r text-gray-600">
                      {getSupplierName(purchase.supplier_id)}
                    </td>
                    <td className="p-1 border-r text-gray-600 text-right">
                      {purchase.grand_total}
                    </td>
                    <td className="p-1 border-r text-gray-600 text-right">
                      {purchase.paid_amount}
                    </td>
                    <td className="p-1 border-r text-gray-600 text-right">
                      {purchase.due_amount}
                    </td>
                    <td className="p-1 border-r text-center">
                      <span className="flex justify-center items-center">
                        {" "}
                        <p
                          className={`border w-1/2 text-xs text-center py-1 px-2 rounded ${statusBgColor}`}
                        >
                          {" "}
                          {paymentStatus}
                        </p>
                      </span>
                    </td>
                    <td className="p-1 text-center action-dropdown">
                      <div className="flex justify-center">
                        <div className="relative">
                          <button
                            className="bg-primary text-sm text-white py-1 px-2 gap-1 rounded-md hover:bg-secondary flex justify-center items-center"
                            onClick={() => toggleActionDropdown(purchase.id)}
                          >
                            Action
                            {actionDropdowns[purchase.id] ? (
                              <IoIosArrowUp />
                            ) : (
                              <IoIosArrowDown />
                            )}
                          </button>
                          {actionDropdowns[purchase.id] && (
                            <div className="absolute bg-primary custom-border right-0 w-48 z-10 p-2 text-start text-white text-sm list-none">
                              <ul>
                                <li
                                  className="flex items-center justify-start px-2 gap-1 custom-border cursor-pointer hover:bg-secondary"
                                  onClick={() => handleViewPurchase(purchase)}
                                >
                                  <GrView /> View
                                </li>
                                <li
                                  className="flex items-center justify-start px-2 gap-1 custom-border cursor-pointer hover:bg-secondary"
                                  onClick={() => handleEditPurchase(purchase)}
                                >
                                  <FaEdit /> Edit
                                </li>

                                <li
                                  className="flex items-center justify-start px-2 gap-1 custom-border cursor-pointer hover:bg-secondary"
                                  onClick={() =>
                                    handleDeletePurchase(purchase.id)
                                  }
                                >
                                  <RiDeleteBin6Fill /> Delete
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="10" className="text-center text-gray-500 py-4">
                  No purchases available.
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr className="bg-gray-300 text-primary ">
              <td className="p-2 border-r text-right font-semibold" colSpan="5">
                Total
              </td>
              <td className="p-1 border-r text-right font-semibold">
                {" "}
                {totalAmount.toFixed(2)}
              </td>
              <td className="p-1 border-r text-right font-semibold">
                {" "}
                {totalPaid.toFixed(2)}
              </td>
              <td className="p-1 border-r text-right font-semibold">
                {" "}
                {totalDue.toFixed(2)}
              </td>
              <td className="p-1" colSpan="2"></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </DashboardLayout>
  );
};

export default PurchaseList;
