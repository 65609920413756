import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { FaBars } from "react-icons/fa6";
import { FaCirclePlus } from "react-icons/fa6";
import { IoCloseCircleSharp } from "react-icons/io5";

import Select from "react-select";
import { Link } from "react-router-dom";
import {
  addMedicine,
  fetchLeafs,
  fetchUnits,
  fetchTypes,
  fetchSuppliers,
  addSupplier,
  addLeaf,
  addUnit,
  addType,
} from "../../api/api"; // Assuming addCustomer is the correct API function
import Swal from "sweetalert2";

const AddMedicine = () => {
  useEffect(() => {
    document.title = "Add Medicine";
  }, []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null); // To handle any API errors
  const [successMessage, setSuccessMessage] = useState(null); // To show success messages
  const [formErrors, setFormErrors] = useState({}); // State for form validation errors

  // State for form inputs
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    strength: "",
    generic_name: "",
    box_size: "",
    unit_name: "",
    shelf: "",
    details: "",
    medicine_type: "",
    price: "",
    supplier_id: "",
    supplier_price: "",
    tax_a: "5",
    tax_b: "10",
  });

  // State for file input
  const [selectedFile, setSelectedFile] = useState(null);
  const [leafOptions, setLeafOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [selectedLeaf, setSelectedLeaf] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [fileError, setFileError] = useState("");
  const fileInputRef = useRef(null);

  const allowedFileTypes = ["image/jpeg", "image/png"]; // Allowed MIME types
  const maxFileSize = 200 * 1024; // 200 KB in bytes
  const requiredWidth = 300; // Required width in pixels
  const requiredHeight = 300;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileError(null); // Reset file error on new selection

    if (file) {
      // Validate file size
      if (file.size > 200 * 1024) {
        // 200 KB in bytes
        setFileError("File size must be less than 200 KB.");
        setSelectedFile(null); // Clear the selected file
        return;
      }

      // Validate file type (only JPG and PNG images)
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        setFileError("File must be a JPG or PNG image.");
        setSelectedFile(null); // Clear the selected file
        return;
      }

      // Create an image object to check dimensions
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        // Validate dimensions
        if (img.width !== 300 || img.height !== 300) {
          setFileError("Image dimensions must be 300x300 pixels.");
          setSelectedFile(null); // Clear the selected file
        } else {
          setSelectedFile(file); // Set the valid file
        }
      };
    } else {
      setSelectedFile(null); // No file selected
    }
  };

  // Load leaf data from fetchLeaf API
  // const handleMedicineSubmit = async (e) => {
  //   e.preventDefault();
  //   setIsSubmitting(true);
  //   setError(null);
  //   setSuccessMessage(null);

  //   // Extract only the name part (before any contact information)
  //   const supplierName = formData.supplier_id.split(" ")[0]; // Get the part before the first space (if that's where the contact starts)

  //   const formDataToSend = new FormData();
  //   // Append form data to FormData object
  //   Object.keys(formData).forEach((key) => {
  //     // Only append fields that are not empty or null
  //     if (formData[key]) {
  //       formDataToSend.append(key, formData[key]);
  //     }
  //   });

  //   // Add only the supplier name (without contact number)
  //   formDataToSend.append("supplier_name", supplierName);

  //   if (selectedFile) {
  //     formDataToSend.append("image", selectedFile);
  //   }

  //   try {
  //     await addMedicine(formDataToSend);

  //     Swal.fire("Success", "Medicine added successfully", "success");
  //     setSuccessMessage("Medicine added successfully!");

  //     // Reset form data
  //     setFormData({
  //       id: "",
  //       name: "",
  //       strength: "",
  //       generic_name: "",
  //       box_size: "",
  //       unit_name: "",
  //       shelf: "",
  //       details: "",
  //       medicine_type: "",
  //       price: "",
  //       supplier_id: "",
  //       supplier_price: "",
  //     });
  //     setSelectedFile(null);
  //     setSelectedLeaf(null);
  //     setSelectedUnit(null);
  //     setSelectedType(null);
  //     setSelectedSupplier(null);

  //     if (fileInputRef.current) {
  //       fileInputRef.current.value = ""; // Clear the file input
  //     }
  //     setFormErrors({});
  //   } catch (err) {
  //     if (err.response && err.response.status === 401) {
  //       const errors = err.response.data.errors;
  //       setFormErrors(errors);
  //       Swal.fire({ icon: "error", title: "Validation Error" });
  //     } else {
  //       Swal.fire(
  //         "Error",
  //         "Failed to add medicine. Please try again.",
  //         "error"
  //       );
  //       setError("Failed to add medicine. Please try again.");
  //       console.error("Error adding medicine:", err);
  //     }
  //   } finally {
  //     setIsSubmitting(false);
  //   }
  // };

  const handleMedicineSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccessMessage(null);

    const formDataToSend = new FormData();

    // Append all form data fields
    Object.keys(formData).forEach((key) => {
      if (formData[key]) {
        formDataToSend.append(key, formData[key]);
      }
    });

    // Append selected file if present
    if (selectedFile) {
      formDataToSend.append("image", selectedFile);
    }

    try {
      await addMedicine(formDataToSend);

      // Show success message
      Swal.fire("Success", "Medicine added successfully", "success");
      setSuccessMessage("Medicine added successfully!");

      // Reset form data and state
      setFormData({
        id: "",
        name: "",
        strength: "",
        generic_name: "",
        box_size: "",
        unit_name: "",
        shelf: "",
        details: "",
        medicine_type: "",
        price: "",
        supplier_id: "",
        supplier_price: "",
        tax_a: "5",
        tax_b: "10",
      });
      setSelectedFile(null);
      setSelectedLeaf(null);
      setSelectedUnit(null);
      setSelectedType(null);
      setSelectedSupplier(null);

      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input
      }
      setFormErrors({});
    } catch (err) {
      if (err.response && err.response.status === 401) {
        const errors = err.response.data.errors;
        setFormErrors(errors);
        Swal.fire({ icon: "error", title: "Validation Error" });
      } else {
        Swal.fire(
          "Error",
          "Failed to add medicine. Please try again.",
          "error"
        );
        setError("Failed to add medicine. Please try again.");
        console.error("Error adding medicine:", err);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const loadLeafOptions = async () => {
      try {
        const response = await fetchLeafs();

        const options = response
          .filter((leaf) => leaf.status === 1) // Only include active leaf types
          .map((leaf) => ({
            value: leaf.id, // Set `type` as the value
            label: `${leaf.type} (${leaf.total_number})`, // Concatenate `type` and `total_number` for the label
          }));

        setLeafOptions(options); // Set options for the dropdown
      } catch (error) {
        console.error("Error fetching leaf data:", error);
      }
    };

    loadLeafOptions();
  }, []);
  // Load unit data from fetchLeaf API
  useEffect(() => {
    const loadUnitOptions = async () => {
      try {
        const response = await fetchUnits(); // Fetch units
        const options = response
          .filter((unit) => unit.status === 1) // Filter for active units
          .map((unit) => ({
            value: unit.id, // Assuming `id` is the unique identifier
            label: unit.name, // Assuming `name` is the name of the unit
          }));
        setUnitOptions(options);
      } catch (error) {
        console.error("Error fetching unit data:", error);
      }
    };
    loadUnitOptions();
  }, []);
  // Load type data from fetchLeaf API
  useEffect(() => {
    const loadTypeOptions = async () => {
      try {
        const response = await fetchTypes();
        const options = response
          .filter((type) => type.status === 1) // Filter for active units
          .map((type) => ({
            value: type.id, // Assuming `id` is the unique identifier
            label: type.name, // Assuming `name` is the name of the unit
          }));
        setTypeOptions(options);
      } catch (error) {
        console.error("Error fetching type data:", error);
      }
    };
    loadTypeOptions();
  }, []);

  // Load supplier data from fetchLeaf API
  useEffect(() => {
    const loadSupplierOptions = async () => {
      try {
        const response = await fetchSuppliers();
        const options = response
          .filter((supplier) => supplier.status === 1) // Filter for active units
          .map((supplier) => ({
            value: supplier.id,
            label: `${supplier.name} (${supplier.mobile})`, // Assuming `name` is the name of the unit
          }));
        setSupplierOptions(options);
      } catch (error) {
        console.error("Error fetching supplier data:", error);
      }
    };
    loadSupplierOptions();
  }, []);

  // add leaf data popup
  const [isLeafModalOpen, setIsLeafModalOpen] = useState(false);
  const [formLeafData, setFormLeafData] = useState({
    type: "",
    total_number: "",
    status: 1,
  });
  const [isLeafSubmitting, setIsLeafSubmitting] = useState(false);
  useEffect(() => {
    // Disable scrolling when the modal is open
    if (isLeafModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Clean up function to remove the class if the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isLeafModalOpen]);

  const handleLeafChange = (e) => {
    const { name, value } = e.target;
    setFormLeafData((prevData) => ({
      ...prevData,
      [name]: name === "status" ? Number(value) : value,
    }));
  };

  const handleLeafSubmit = async (e) => {
    e.preventDefault();
    setIsLeafSubmitting(true);

    try {
      await addLeaf(formLeafData); // Assuming this function sends data to your API
      Swal.fire({
        title: "Success!",
        text: "Leaf has been added successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      setFormLeafData({
        type: "",
        total_number: "",
        status: 1, // Resetting status to its default value
      });
      setIsLeafModalOpen(false);
    } catch (error) {
      console.error("Failed to add leaf", error);
      Swal.fire({
        title: "Error!",
        text: "There was an error adding the leaf. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLeafSubmitting(false);
    }
  };

  // add unit data popup
  const [isUnitModalOpen, setIsUnitModalOpen] = useState(false);
  const [formUnitData, setFormUnitData] = useState({
    name: "",
    status: 1, // default status
  });
  const [isUnitSubmitting, setIsUnitSubmitting] = useState(false);

  useEffect(() => {
    // Disable scrolling when the modal is open
    if (isUnitModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Clean up function to remove the class if the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isUnitModalOpen]);
  // Handle input change
  const handleUnitChange = (e) => {
    const { name, value } = e.target;
    setFormUnitData((prevData) => ({
      ...prevData,
      [name]: name === "status" ? Number(value) : value,
    }));
  };
  // Handle form submission
  const handleUnitSubmit = async (e) => {
    e.preventDefault();
    setIsUnitSubmitting(true);

    try {
      await addUnit(formUnitData); // Call the addUnit API function

      // Show success popup
      Swal.fire({
        title: "Success!",
        text: "Unit has been added successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      setFormUnitData({
        name: "",
        status: 1,
      });
      setIsUnitModalOpen(false);
    } catch (error) {
      console.error("Error adding unit:", error);

      // Show error popup
      Swal.fire({
        title: "Error!",
        text: "There was an error adding the unit. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsUnitSubmitting(false);
    }
  };

  // add type data popup
  const [isTypeModalOpen, setIsTypeModalOpen] = useState(false);
  const [formTypeData, setFormTypeData] = useState({
    name: "",
    status: 1, // default status
  });
  const [isTypeSubmitting, setIsTypeSubmitting] = useState(false);

  useEffect(() => {
    // Disable scrolling when the modal is open
    if (isTypeModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Clean up function to remove the class if the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isTypeModalOpen]);
  // Handle input change
  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFormTypeData((prevData) => ({
      ...prevData,
      [name]: name === "status" ? Number(value) : value,
    }));
  };
  // Handle form submission
  const handleTypeSubmit = async (e) => {
    e.preventDefault();
    setIsTypeSubmitting(true);

    try {
      await addType(formTypeData); // Call the addUnit API function

      // Show success popup
      Swal.fire({
        title: "Success!",
        text: "Type has been added successfully.",
        icon: "success",
        confirmButtonText: "OK",
      });
      setFormTypeData({
        name: "",
        status: 1,
      });
      setIsTypeModalOpen(false);
    } catch (error) {
      console.error("Error adding type:", error);

      // Show error popup
      Swal.fire({
        title: "Error!",
        text: "There was an error adding the type. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsTypeSubmitting(false);
    }
  };

  // add supplier data popup
  const [isSupplierSubmitting, setIsSupplierSubmitting] = useState(false);
  const [formSupplierErrors, setFormSupplierErrors] = useState({}); // State for form validation errors
  const [nextSupplierID, setNextSupplierID] = useState("");
  const [isSupplierModalOpen, setIsSupplierModalOpen] = useState(false);
  const [formSupplierData, setFormSupplierData] = useState({
    id: "",
    name: "",
    mobile: "",
    email: "",
    brand: "",
    tax_number: "",
    opening_balance: 0,
    address: "",
  });

  useEffect(() => {
    // Disable scrolling when the modal is open
    if (isSupplierModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    // Clean up function to remove the class if the component unmounts
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isSupplierModalOpen]);

  const fetchAndSetNextSupplierID = async () => {
    try {
      const suppliers = await fetchSuppliers(); // Fetch the supplier list
      console.log("Fetched suppliers:", suppliers); // Log the suppliers

      if (suppliers && suppliers.length > 0) {
        const lastSupplier = suppliers[suppliers.length - 1];
        const lastSupplierCode =
          lastSupplier.supplier_code || `SU${lastSupplier.id}`; // Use supplier ID directly if supplier_code is not present

        console.log("Last supplier code (fallback if null):", lastSupplierCode); // Log the fallback code

        const numericPart = parseInt(lastSupplierCode.slice(2), 10); // Parse the numeric part after "SU"
        const newID = `SU${numericPart + 1}`; // Generate new ID without leading zeros
        console.log("Next supplier ID:", newID); // Log the generated ID
        setNextSupplierID(newID); // Set the next supplier ID
      } else {
        setNextSupplierID("SU1"); // Default ID if no suppliers found (without leading zeros)
      }
    } catch (err) {
      console.error("Error fetching suppliers:", err);
    }
  };

  useEffect(() => {
    fetchAndSetNextSupplierID();
  }, []);

  const handleSupplierChange = (e) => {
    const { name, value } = e.target;
    setFormSupplierData({
      ...formSupplierData,
      [name]: value,
    });
  };

  // Validate form inputs
  const validateForm = () => {
    const errors = {};
    // Required fields validation
    if (!formSupplierData.name.trim()) {
      errors.name = "Supplier name is required.";
    }
    if (!formSupplierData.mobile.trim()) {
      errors.mobile = "Mobile number is required.";
    } else if (!/^01[2-9]\d{8}$/.test(formSupplierData.mobile)) {
      errors.mobile = "Mobile number must be 11 digits and start with 01.";
    }
    if (!formSupplierData.email.trim()) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(formSupplierData.email)) {
      errors.email = "Email is not valid.";
    }
    // No validation for optional fields (previous_due and address)
    return errors;
  };

  const handleSupplierSubmit = async (e) => {
    e.preventDefault();
    setIsSupplierSubmitting(true);
    setError(null);
    setSuccessMessage(null);

    // Validate form inputs
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormSupplierErrors(errors);
      setIsSupplierSubmitting(false);
      return;
    }

    try {
      // Make API call to add supplier
      const response = await addSupplier({
        ...formSupplierData,
        id: nextSupplierID,
      });
      Swal.fire("Success", "Supplier added successfully", "success");
      setSuccessMessage("Supplier added successfully!");

      // Reset form data
      setFormSupplierData({
        name: "",
        mobile: "",
        email: "",
        brand: "",
        tax_number: "",
        opening_balance: 0,
        address: "",
      });
      setFormSupplierErrors({});
      setIsSupplierModalOpen(false);
      setNextSupplierID(""); // Reset Supplier ID before generating the next one
      // Generate the next customer ID
      await fetchAndSetNextSupplierID(); // Trigger the next customer ID generation without refreshing
    } catch (err) {
      // Check for validation errors from the API
      if (err.response && err.response.status === 401) {
        const errors = err.response.data.errors; // Laravel validation errors
        setFormSupplierErrors(errors); // Set form errors state for rendering

        // Display appropriate error messages in SweetAlert
        const errorMessages = [];
        if (errors.email) {
          errorMessages.push(
            "Email is already used, please try another email."
          );
        }
        if (errors.mobile) {
          errorMessages.push(
            "Mobile number is already used, please try another mobile number."
          );
        }

        Swal.fire({
          icon: "error",
          title: "Validation Error",
          text: errorMessages.join(" "),
        });
      } else {
        // Handle other types of errors
        Swal.fire(
          "Error",
          "Failed to add supplier. Please try again.",
          "error"
        );
        setError("Failed to add supplier. Please try again.");
        console.error("Error adding supplier:", err);
      }
    } finally {
      setIsSupplierSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="mb-5 flex justify-between items-center">
          <h2 className="text-lg font-semibold text-primary ">Add Medicine</h2>
          <div>
            <Link to="/medicinelist">
              <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
                <span>
                  <FaBars />
                </span>
                Medicine List
              </p>
            </Link>
          </div>
        </div>
        <form
          className="grid grid-cols-2 gap-5"
          onSubmit={handleMedicineSubmit}
        >
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Medicine Name*
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="capitalize px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Strength</label>
            <input
              type="text"
              name="strength"
              value={formData.strength}
              onChange={handleInputChange}
              placeholder="Ex. 10 Mg"
              className="capitalize px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Generic Name*
            </label>
            <input
              type="text"
              name="generic_name"
              value={formData.generic_name}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="capitalize px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
              required
            />
          </div>

          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label for="box_size" class="text-primary font-semibold mb-1">
                Box Size*
              </label>
              <div>
                <Select
                  id="boxSize"
                  name="box_size"
                  options={leafOptions}
                  value={selectedLeaf}
                  onChange={(option) => {
                    setSelectedLeaf(option); // Update selected option
                    if (option) {
                      setFormData({
                        ...formData,
                        type: option.value,
                        box_size: option.label,
                      });
                    }
                  }}
                  placeholder="Select Box Size"
                  isSearchable
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      borderRadius: "8px",
                      boxShadow: state.isFocused ? "0 0 0 0.5px #0090FF" : null,
                    }),
                    dropdownIndicator: (base) => ({
                      ...base,
                      color: "#0090FF",
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: "#A0AEC0",
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused ? "#0090FF" : "#fff",
                      color: state.isFocused ? "#000" : "#333",
                      cursor: "pointer",
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: "#333",
                    }),
                  }}
                />
              </div>
            </div>
            <div
              className="bg-primary px-6 py-1.5 rounded-md cursor-pointer"
              onClick={() => setIsLeafModalOpen(true)}
            >
              <span className="text-2xl text-white">
                <FaCirclePlus />
              </span>
            </div>
          </div>

          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label for="unit" class="text-primary font-semibold mb-1">
                Unit*
              </label>
              <Select
                id="unit_name"
                name="unit_name"
                options={unitOptions}
                value={selectedUnit}
                onChange={(option) => {
                  setSelectedUnit(option); // Update selected option
                  if (option) {
                    setFormData({
                      ...formData,
                      unit_name: option.value,
                      unit_name: option.label,
                    });
                  }
                }}
                placeholder="Select Unit"
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,

                    borderRadius: "8px",
                    boxShadow: state.isFocused ? "0 0 0 0.5px #0090FF" : null,
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#0090FF",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#A0AEC0",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? "#0090FF" : "#fff",
                    color: state.isFocused ? "#000" : "#333",
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#333",
                  }),
                }}
              />
            </div>
            <div
              className="bg-primary px-6 py-1.5 rounded-md cursor-pointer"
              onClick={() => setIsUnitModalOpen(true)}
            >
              <span className="text-2xl text-white">
                <FaCirclePlus />
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Shelf</label>
            <input
              type="text"
              name="shelf"
              value={formData.shelf}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="capitalize px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Medicine Details
            </label>
            <input
              type="text"
              name="details"
              value={formData.details}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="capitalize px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
            />
          </div>

          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label for="type" class="text-primary font-semibold mb-1  ">
                Medicine Type*
              </label>
              <Select
                id="medicine_type"
                name="medicine_type"
                options={typeOptions}
                value={selectedType}
                onChange={(option) => {
                  setSelectedType(option); // Update selected option
                  if (option) {
                    setFormData((prevData) => ({
                      ...prevData,
                      medicine_type: option.value, // Set medicine_type value
                      medicine_type: option.label, // Set medicine_type value
                    }));
                  }
                }}
                placeholder="Select Medicine Type"
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderRadius: "8px",
                    boxShadow: state.isFocused ? "0 0 0 0.5px #0090FF" : null,
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#0090FF",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#A0AEC0",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? "#0090FF" : "#fff",
                    color: state.isFocused ? "#000" : "#333",
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#333",
                  }),
                }}
              />
            </div>
            <div
              className="bg-primary px-6 py-1.5 rounded-md cursor-pointer"
              onClick={() => setIsTypeModalOpen(true)}
            >
              <span className="text-2xl text-white">
                <FaCirclePlus />
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Price*</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
              required
            />
          </div>

          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label for="supplier" class="text-primary font-semibold mb-1">
                Supplier*
              </label>
              <Select
                id="supplier_id"
                name="supplier_id"
                options={supplierOptions}
                value={selectedSupplier}
                onChange={(option) => {
                  setSelectedSupplier(option);
                  setFormData((prevData) => ({
                    ...prevData,
                    supplier_id: option.value, // Set supplier_id correctly
                  }));
                }}
                placeholder="Select Supplier"
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderRadius: "8px",
                    boxShadow: state.isFocused ? "0 0 0 0.5px #0090FF" : null,
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#0090FF",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#A0AEC0",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? "#0090FF" : "#fff",
                    color: state.isFocused ? "#000" : "#333",
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#333",
                  }),
                }}
              />
            </div>
            <div
              className="bg-primary px-6 py-1.5 rounded-md cursor-pointer"
              onClick={() => setIsSupplierModalOpen(true)}
            >
              <span className="text-2xl text-white">
                <FaCirclePlus />
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Image</label>
            <input
              type="file"
              name="image"
              ref={fileInputRef} // Attach ref to the file input
              onChange={handleFileChange}
              className="px-2 py-1 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
              accept=".jpg, .jpeg, .png"
            />
            {fileError && (
              <p className="text-red-500 text-sm -mb-5">{fileError}</p>
            )}
          </div>

          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label className="text-primary font-semibold mb-1">Tax A %</label>
              <input
                type="text"
                name="tax_a"
                value={formData.tax_a}
                onChange={handleInputChange}
                placeholder=""
                className="px-2 py-1.5 border text-gray-500 border-gray-300 bg-gray-300 rounded-lg  focus:outline-none "
                readOnly
              />
            </div>
            <div className="bg-primary px-6 py-1.5 rounded-md cursor-pointer">
              <span className="text-2xl text-white">
                <FaCirclePlus />
              </span>
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Supplier Price*
            </label>
            <input
              type="text"
              name="supplier_price"
              value={formData.supplier_price}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
              required
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Tax B %</label>
            <input
              type="text"
              name="tax_b"
              value={formData.tax_b}
              onChange={handleInputChange}
              placeholder=""
              className="px-2 py-1.5 border text-gray-500 border-gray-300 bg-gray-300 rounded-lg  focus:outline-none "
              readOnly
            />
          </div>

          <div className="col-span-full flex justify-center w-full">
            <button
              type="submit"
              className="w-60 mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
        {isLeafModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
            <div className="bg-white px-5 py-4 custom-border rounded-lg shadow-lg w-full max-w-md ">
              <div className="mb-5 flex justify-between items-center">
                <h2 className="text-lg font-semibold text-primary">Add Leaf</h2>
                <button
                  onClick={() => setIsLeafModalOpen(false)}
                  className="text-primary hover:text-red-500 text-3xl"
                >
                  <span>
                    <IoCloseCircleSharp />
                  </span>
                </button>
              </div>
              <form className="grid gap-5 px-5" onSubmit={handleLeafSubmit}>
                <div className="flex flex-col">
                  <label className="text-primary font-semibold mb-1">
                    Leaf Type*
                  </label>
                  <input
                    type="text"
                    name="type"
                    value={formLeafData.type}
                    onChange={handleLeafChange}
                    placeholder="Enter Leaf Type"
                    className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                    required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-primary font-semibold mb-1">
                    Total Number*
                  </label>
                  <input
                    type="number"
                    name="total_number"
                    value={formLeafData.total_number}
                    onChange={handleLeafChange}
                    placeholder="Enter Total Number"
                    className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                    required
                  />
                </div>
                <div className="flex gap-3 items-center mt-1">
                  <p className="text-primary font-semibold">Status*</p>
                  <div className="flex items-center">
                    <input
                      id="active"
                      type="radio"
                      value={1}
                      name="status"
                      checked={formLeafData.status === 1}
                      onChange={handleLeafChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-600"
                    />
                    <label
                      htmlFor="active"
                      className="ms-2 text-sm font-medium text-primary"
                    >
                      Active
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="inactive"
                      type="radio"
                      value={0}
                      name="status"
                      checked={formLeafData.status === 0}
                      onChange={handleLeafChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-600"
                    />
                    <label
                      htmlFor="inactive"
                      className="ms-2 text-sm font-medium text-primary"
                    >
                      Inactive
                    </label>
                  </div>
                </div>
                <div className="flex justify-center w-full">
                  <button
                    type="submit"
                    className="w-60 mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
                  >
                    {isLeafSubmitting ? "Saving..." : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {isUnitModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
            <div className="bg-white px-5 py-4 custom-border rounded-lg shadow-lg w-full max-w-md ">
              <div className="mb-5 flex justify-between items-center">
                <h2 className="text-lg font-semibold text-primary">Add Unit</h2>
                <button
                  onClick={() => setIsUnitModalOpen(false)}
                  className="text-primary hover:text-red-500 text-3xl"
                >
                  <span>
                    <IoCloseCircleSharp />
                  </span>
                </button>
              </div>
              <form
                className="grid grid-cols gap-5 "
                onSubmit={handleUnitSubmit}
              >
                <div className="col-span-full flex justify-center w-full">
                  <div className="flex flex-col w-96 ">
                    <label className="text-primary font-semibold mb-1">
                      Unit Name*
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formUnitData.name}
                      onChange={handleUnitChange}
                      placeholder="Enter Here"
                      className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                      required
                    />

                    <div className="flex gap-3 items-center mt-1 ">
                      <p className="text-primary font-semibold">Status*</p>
                      <div className="flex items-center ">
                        <input
                          id="active"
                          type="radio"
                          value={1}
                          name="status"
                          checked={formUnitData.status === 1}
                          onChange={handleUnitChange}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600   dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="active"
                          className="ms-2 text-sm font-medium text-primary dark:text-gray-300"
                        >
                          Active
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="inactive"
                          type="radio"
                          value={0}
                          name="status"
                          checked={formUnitData.status === 0}
                          onChange={handleUnitChange}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600   dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="inactive"
                          className="ms-2 text-sm font-medium text-primary dark:text-gray-300"
                        >
                          Inactive
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-full flex justify-center w-full">
                  <button
                    type="submit"
                    className="w-60 mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
                  >
                    {isUnitSubmitting ? "Saving..." : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {isTypeModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
            <div className="bg-white px-5 py-4 custom-border rounded-lg shadow-lg w-full max-w-md ">
              <div className="mb-5 flex justify-between items-center">
                <h2 className="text-lg font-semibold text-primary">Add Type</h2>
                <button
                  onClick={() => setIsTypeModalOpen(false)}
                  className="text-primary hover:text-red-500 text-3xl"
                >
                  <span>
                    <IoCloseCircleSharp />
                  </span>
                </button>
              </div>
              <form
                className="grid grid-cols gap-5 "
                onSubmit={handleTypeSubmit}
              >
                <div className="col-span-full flex justify-center w-full">
                  <div className="flex flex-col w-96 ">
                    <label className="text-primary font-semibold mb-1">
                      Type Name*
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formTypeData.name}
                      onChange={handleTypeChange}
                      placeholder="Enter Here"
                      className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                      required
                    />

                    <div className="flex gap-3 items-center mt-1 ">
                      <p className="text-primary font-semibold">Status*</p>
                      <div className="flex items-center ">
                        <input
                          id="active"
                          type="radio"
                          value={1}
                          name="status"
                          checked={formTypeData.status === 1}
                          onChange={handleTypeChange}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600   dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="active"
                          className="ms-2 text-sm font-medium text-primary dark:text-gray-300"
                        >
                          Active
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          id="inactive"
                          type="radio"
                          value={0}
                          name="status"
                          checked={formTypeData.status === 0}
                          onChange={handleTypeChange}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 dark:focus:ring-blue-600   dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="inactive"
                          className="ms-2 text-sm font-medium text-primary dark:text-gray-300"
                        >
                          Inactive
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-span-full flex justify-center w-full">
                  <button
                    type="submit"
                    className="w-60 mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
                  >
                    {isTypeSubmitting ? "Saving..." : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {isSupplierModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
            <div className="bg-white px-5 py-4 custom-border rounded-lg shadow-lg w-1/2 ">
              <div className="mb-5 flex justify-between items-center">
                <h2 className="text-lg font-semibold text-primary">
                  Add Supplier
                </h2>
                <button
                  onClick={() => setIsSupplierModalOpen(false)}
                  className="text-primary hover:text-red-500 text-3xl"
                >
                  <span>
                    <IoCloseCircleSharp />
                  </span>
                </button>
              </div>
              <form
                className="grid grid-cols-2 gap-2  mx-3"
                onSubmit={handleSupplierSubmit}
              >
                {" "}
                <div className=" flex flex-col">
                  <label className="text-primary font-semibold mb-1">
                    Supplier ID
                  </label>
                  <input
                    type="text"
                    name="id"
                    value={nextSupplierID || "Generating..."}
                    className="px-2 py-1.5 border  bg-gray-300 rounded-lg  focus:outline-none"
                    readOnly
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-primary font-semibold mb-1">
                    Supplier Name*
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formSupplierData.name}
                    onChange={handleSupplierChange}
                    placeholder="Enter Here"
                    className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                    // required
                  />
                  {formSupplierErrors.name && (
                    <p className="text-red-500 text-sm -mb-1">
                      {formSupplierErrors.name}
                    </p>
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-primary font-semibold mb-1">
                    Mobile No*
                  </label>
                  <input
                    type="tel"
                    name="mobile"
                    value={formSupplierData.mobile}
                    onChange={handleSupplierChange}
                    placeholder="01xxxxxxxxx"
                    className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                    maxLength="11"
                  />

                  {formSupplierErrors.mobile && (
                    <p className="text-red-500 text-sm">
                      {Array.isArray(formSupplierErrors.mobile)
                        ? formSupplierErrors.mobile.join(", ")
                        : formSupplierErrors.mobile}
                    </p> // Display mobile errors
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-primary font-semibold mb-1">
                    Email Address*
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formSupplierData.email}
                    onChange={handleSupplierChange}
                    placeholder="Enter Here"
                    className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                    // required
                  />

                  {formSupplierErrors.email && (
                    <p className="text-red-500 text-sm">
                      {Array.isArray(formSupplierErrors.email)
                        ? formSupplierErrors.email.join(", ")
                        : formSupplierErrors.email}
                    </p> // Display email errors
                  )}
                </div>
                <div className="flex flex-col">
                  <label className="text-primary font-semibold mb-1">
                    Brand
                  </label>
                  <input
                    type="text"
                    name="brand"
                    value={formSupplierData.brand}
                    onChange={handleSupplierChange}
                    placeholder="Enter Here"
                    className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                    // required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-primary font-semibold mb-1">
                    Tax Number
                  </label>
                  <input
                    type="text"
                    name="tax_number"
                    value={formSupplierData.tax_number}
                    onChange={handleSupplierChange}
                    placeholder="Enter Here"
                    className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                    // required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-primary font-semibold mb-1">
                    Opening Balance
                  </label>
                  <input
                    type="number"
                    name="opening_balance"
                    value={formSupplierData.opening_balance}
                    onChange={handleSupplierChange}
                    placeholder="Enter Here"
                    className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none"
                    //required
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-primary font-semibold mb-1">
                    Address
                  </label>
                  <textarea
                    type="text"
                    name="address"
                    value={formSupplierData.address}
                    onChange={handleSupplierChange}
                    placeholder="Enter Here"
                    className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none h-32"
                    // required
                  />
                </div>
                <div className="col-span-full flex justify-center w-full">
                  <button
                    type="submit"
                    className="w-60 mt-2 bg-primary text-white py-2 px-4 rounded-lg hover:bg-secondary transition"
                    disabled={isSupplierSubmitting}
                  >
                    {isSupplierSubmitting ? "Saving..." : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default AddMedicine;
