import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaBars } from "react-icons/fa6";
import { IoIosPrint } from "react-icons/io";
import { FaFileInvoice } from "react-icons/fa";
import DashboardLayout from "../../layouts/DashboardLayout";
import numWords from "num-words";
import {
  fetchSuppliers,
  fetchPurchases,
  fetchMedicinesBySupplier,
} from "../../api/api";

const ViewPurchase = () => {
  useEffect(() => {
    document.title = "View Purchase";
  }, []);
  const location = useLocation();
  const { purchase } = location.state || {};

  const [suppliers, setSuppliers] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [medicines, setMedicines] = useState([]); // State to store the fetched data

  const formatNumberToWords = (number) => {
    if (!number || isNaN(number)) return "N/A";
    return numWords(number).replace(/^\w/, (c) => c.toUpperCase());
  };

  // Fetch the data when the component mounts
  useEffect(() => {
    fetchSuppliers()
      .then((data) => setSuppliers(data))
      .catch((error) => console.error("Error fetching suppliers:", error));

    fetchPurchases()
      .then((data) => setPurchases(data))
      .catch((error) => console.error("Error fetching purchases:", error));

    // Fetch medicines data
    fetchMedicinesBySupplier(purchase?.supplier_id)
      .then((data) => setMedicines(data))
      .catch((error) => console.error("Error fetching medicines:", error));
  }, [purchase?.supplier_id]);

  const handlePrint = () => {
    const printContent = document.getElementById("print-section");
    if (!printContent) {
      console.error("Print section not found.");
      return;
    }
    const content = printContent.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Invoice</title>
          <link href="https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css" rel="stylesheet">
        </head>
        <body>
          ${content}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <DashboardLayout>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="mb-5 flex justify-between items-center">
          <h2 className="text-lg font-semibold text-primary ">View Purchase</h2>

          <div>
            <Link to="/purchaselist">
              <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
                <span>
                  <FaBars />
                </span>
                Purchase List
              </p>
            </Link>
          </div>
        </div>
        <div className="flex justify-between items-center my-2">
          <h2
            className="font-semibold text-primary bg-gray-200 px-2 py-1 rounded
           "
          >
            Invoice No: INV{purchase.invoice_no}
          </h2>
          <div
            className="bg-primary shadow shadow-primary hover:bg-secondary hover:shadow-secondary text-white px-2 py-1 rounded flex items-center gap-1 cursor-pointer"
            onClick={handlePrint}
          >
            {" "}
            <span>
              <IoIosPrint />
            </span>
            Print
          </div>
        </div>
        <div className="grid grid-cols text-center bg-gray-200 custom-border p-5">
          <div className="mb-5">
            <div>
              <p className="text-3xl text-primary font-extrabold flex justify-center items-center gap-1">
                <span className="text-2xl">
                  {" "}
                  <FaFileInvoice />
                </span>
                CVS <span className="text-2xl font-semibold"> pharmacy®</span>
              </p>
              <p className="text-sm">
                <span className="font-semibold">Phone No: </span>017*********
              </p>
              <p className="text-sm">
                <span className="font-semibold">Email: </span>info@opt2cash.com
              </p>
              <p className="text-sm font-semibold">
                159/A, Kadirganj, Ghoramara, Boalia, Rajshahi-6100
              </p>
              <p className="text-sm ">
                <span className="text-primary font-bold">Invoice No: </span>
                INV{purchase.invoice_no}
              </p>
            </div>

            <div className="flex justify-between items-end my-5 text-sm">
              <div className="text-start">
                <h1 className="text-secondary text-xl">BILLING FROM</h1>
                <p>
                  <span className="text-primary font-bold">Purchase ID: </span>
                  PUR{purchase.id}
                </p>
                <p className="capitalize">
                  <span className="font-bold">Supplier Name: </span>
                  {suppliers.find(
                    (supplier) => supplier.id === purchase.supplier_id
                  )?.name || "N/A"}
                </p>
                <p>
                  {" "}
                  <span className="font-bold">Phone No: </span>
                  {suppliers.find(
                    (supplier) => supplier.id === purchase.supplier_id
                  )?.mobile || "N/A"}
                </p>
                <p>
                  {" "}
                  <span className="font-bold">Email: </span>{" "}
                  {suppliers.find(
                    (supplier) => supplier.id === purchase.supplier_id
                  )?.email || "N/A"}
                </p>
              </div>

              <div className="text-start text-sm">
                <p>
                  <span className="font-bold">Date: </span>{" "}
                  {new Date().toLocaleString("en-GB", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </p>
              </div>
            </div>
          </div>
          <table className=" w-full">
            <thead>
              <tr className="bg-gray-300 text-sm">
                <th className="py-1 border-r">SL</th>
                <th className="p-1 border-r">Medicine Name</th>
                <th className="p-1 border-r">Box Qty</th>
                <th className="p-1 border-r ">PCS</th>
                <th className="p-1 border-r ">Suppler Price</th>
                <th className="p-1 border-r ">Per Pcs Price</th>
                <th className="p-1  ">Total Purchase Price</th>
              </tr>
            </thead>
            <tbody>
              {purchase.purchase_items.length > 0 ? (
                purchase.purchase_items.map((item, index) => {
                  const perPcsPrice = item.quantity
                    ? (item.supplier_price / item.quantity).toFixed(2)
                    : "N/A";

                  const medicineName = medicines.find(
                    (medicine) => medicine.id === item.medicine_id
                  )
                    ? `${
                        medicines.find(
                          (medicine) => medicine.id === item.medicine_id
                        ).name
                      } (${
                        medicines.find(
                          (medicine) => medicine.id === item.medicine_id
                        ).strength
                      })`
                    : "N/A";

                  return (
                    <tr
                      key={item.id}
                      className="text-sm border-b border-gray-300"
                    >
                      <td className="py-1 px-2  ">{index + 1}</td>
                      <td className="py-1 px-2  ">
                        {medicineName || "N/A"}
                      </td>{" "}
                      <td className="py-1 px-2  ">{item.box_qty || "N/A"}</td>
                      <td className="py-1 px-2 ">{item.quantity || "N/A"}</td>
                      <td className="py-1 px-2 ">
                        {item.supplier_price || "N/A"}
                      </td>
                      <td className="py-1 px-2 ">{perPcsPrice || "N/A"}</td>
                      <td className="py-1 px-2 ">
                        {item.total_purchase_price || "N/A"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" className="text-center py-2">
                    No purchase items available
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot className="text-sm">
              <tr>
                <td className="p-1 text-right font-semibold" colSpan="6">
                  Sub Total:
                </td>
                <td className="p-1  text-center" colSpan="1">
                  {purchase.sub_total || "N/A"}
                </td>
              </tr>
              <tr>
                <td className="p-1  text-right font-semibold" colSpan="6">
                  Discount:
                </td>
                <td className="p-1  text-center" colSpan="1">
                  {purchase.discount || "N/A"}
                </td>
              </tr>
              <tr>
                <td className="p-1 text-right font-semibold" colSpan="6">
                  Total Vat:
                </td>
                <td className="p-1  text-center" colSpan="1">
                  {purchase.vat || "N/A"}
                </td>
              </tr>
              <tr>
                <td className="p-1  text-right font-semibold" colSpan="6">
                  Grand Total:
                </td>
                <td className="p-1  text-center" colSpan="1">
                  {purchase.grand_total || "N/A"}
                </td>
              </tr>
              <tr>
                <td className="p-1  text-right font-semibold" colSpan="6">
                  Paid:
                </td>
                <td className="p-1  text-center" colSpan="1">
                  {purchase.paid_amount || "N/A"}
                </td>
              </tr>
              <tr>
                <td className="p-1  text-right font-semibold" colSpan="6">
                  Due:
                </td>
                <td className="p-1  text-center" colSpan="1">
                  {purchase.due_amount || "N/A"}
                </td>
              </tr>
            </tfoot>
          </table>
          <div className="text-start">
            <p className="text-primary font-semibold">Payment Details</p>
            <p className="text-sm">
              <span className="font-semibold">Payment Method: </span>
              {purchase.payment_type || "N/A"}
            </p>
            <p className="text-sm flex gap-1">
              <span className="font-semibold">Payment Amount: </span>
              {purchase.paid_amount} [
              <span className="font-semibold">In Text:</span>{" "}
              {formatNumberToWords(purchase.paid_amount)} Tk. Only ]
            </p>
          </div>
        </div>
      </div>
      <div id="print-section" style={{ display: "none" }}>
        <div className=" bg-gray-100 p-3 mt-5 w-80 mx-auto">
          <div>
            <p className="text-lg text-primary font-extrabold flex justify-center items-center text-center gap-1">
              <span className="text-lg">
                {" "}
                <FaFileInvoice />
              </span>
              CVS <span className="text-sm font-semibold"> pharmacy®</span>
            </p>
            <p className="text-sm text-center font-semibold">
              159/A, Kadirganj, Ghoramara, Boalia, Rajshahi-6100
            </p>
            <div className="text-sm">
              <p>
                <span className="font-semibold">Phone No: </span>01234567890
              </p>
              <p>
                <span className="font-semibold">Email: </span>info@opt2cash.com
              </p>
              <p>
                <span className="text-primary font-semibold">Invoice No: </span>
                INV{purchase.invoice_no}
              </p>
            </div>
          </div>
          <p className="flex justify-center text-md mt-3">INVOICE</p>
          <div className="border-t-2 border-dotted border-gray-600 mb-3"></div>
          <div className="invoice-details text-sm mt-2 mb-3">
            <p className="flex justify-between ">
              <span className="font-semibold">Date:</span>{" "}
              {new Date().toLocaleString("en-GB", {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hour12: true,
              })}
            </p>
            <p className="flex justify-between ">
              <span className="font-semibold">Supplier Name:</span>{" "}
              {suppliers[0]?.name || "N/A"}
            </p>
            <p className="flex justify-between ">
              <span className="font-semibold">Phone No:</span>{" "}
              {suppliers[0]?.mobile || "N/A"}
            </p>
            <p className="flex justify-between">
              <span className="font-semibold">Email:</span>
              {suppliers[0]?.email || "N/A"}
            </p>
          </div>
          <div className="border-t-2 border-dotted border-gray-600 my-3"></div>
          <table className="medicine-table w-full text-sm">
            <thead>
              <tr>
                <th className="border-b ">SL.</th>
                <th className="border-b ">Medicine</th>
                <th className="border-b ">Box Qty</th>
                <th className="border-b ">PCS</th>
                <th className="border-b ">Price</th>
                <th className="border-b ">Total</th>
              </tr>
            </thead>
            <tbody>
              {purchase.purchase_items.map((item, index) => {
                const medicine = medicines.find(
                  (medicine) => medicine.id === item.medicine_id
                );
                const medicineName = medicine
                  ? `${medicine.name} (${medicine.strength || "N/A"})`
                  : "N/A";

                return (
                  <tr key={item.id} className="text-center">
                    <td className="">{index + 1}.</td>
                    <td className="text-start">{medicineName}</td>
                    <td className="">{item.box_qty}</td>
                    <td className="">{item.quantity}</td>
                    <td className="">{item.supplier_price}</td>
                    <td className="">{item.total_purchase_price}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="border-t-2 border-dotted border-gray-600 my-3"></div>
          <div className="totals text-sm">
            <p className="flex justify-between">
              <span className="font-semibold">Sub Total:</span>{" "}
              {purchase.sub_total}
            </p>
            <p className="flex justify-between">
              <span className="font-semibold">Discount:</span>{" "}
              {purchase.discount}
            </p>
            <p className="flex justify-between">
              <span className="font-semibold">Grand Total:</span>{" "}
              {purchase.grand_total}
            </p>
            <p className="flex justify-between">
              <span className="font-semibold">Paid:</span>{" "}
              {purchase.paid_amount}
            </p>
            <p className="flex justify-between">
              <span className="font-semibold">Due:</span> {purchase.due_amount}
            </p>
          </div>
          <p className="text-center text-md"> PAYMENT DETAILS</p>
          <div className="border-t-2 border-dotted border-gray-600 mb-3"></div>
          <p className="flex justify-center"></p>
          <div className=" text-left text-sm mt-2">
            <p>
              <span className="font-semibold">Payment Method:</span> Bank
            </p>
            <p>
              <span className="font-semibold">Payment Amount:</span>{" "}
              {purchase.paid_amount} (
              {formatNumberToWords(purchase.paid_amount)} Tk. Only)
            </p>
          </div>
          <div className="border-t-2 border-dotted border-gray-600 my-3"></div>
          <div className="text-center text-sm">
            <p className="font-semibold">Thank You For Choosing Us</p>
            <p>Software Developed By MD Infotech</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ViewPurchase;
