// import React, { useState } from "react";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// import login from "../../src/assets/login.png";
// import { useNavigate } from "react-router-dom";
// import useAuth from "../hooks/useAuth";

// const Login = () => {
//   const { login: loginUser, error, authToken } = useAuth();
//   const [showPassword, setShowPassword] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [isLoading, setIsLoading] = useState(false); // For loading state
//   const navigate = useNavigate();

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     setIsLoading(true); // Set loading to true when the request starts

//     const response = await loginUser({ email, password });

//     setIsLoading(false); // Set loading to false once the request is completed

//     if (response.success) {
//       navigate("/"); // Redirect to the dashboard on successful login
//     } else {
//       // Show the error message if login fails
//       console.log(response.message);
//     }
//   };
//   return (
//     <div className="lg:h-screen bg-gray-100 lg:flex lg:justify-between items-center">
//       <div className="lg:w-[40%]">
//         <div className="m-10 lg:pt-0 pt-5">
//           <h1 className="lg:text-4xl text-3xl font-semibold">
//             Welcome to Opt2Cash
//           </h1>
//           <p className="text-xs px-1 py-0.5 bg-secondary inline-block text-white rounded">
//             Pharmacy
//           </p>
//           <form onSubmit={handleSubmit} className="my-5">
//             {" "}
//             <div className="mb-6">
//               <label
//                 htmlFor="email"
//                 className="block text-gray-600 text-sm mb-1"
//               >
//                 Email or Username
//               </label>
//               <input
//                 type="text"
//                 id="email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 className="w-full border border-gray-300  px-2 py-1 text-primary  rounded focus:outline-none focus:ring-1 focus:ring-secondary focus:border-transparent"
//                 placeholder="Enter Here"
//                 required
//               />
//               {error && (
//                 <div className="-mb-6 text-red-600 text-sm">{error}</div>
//               )}
//             </div>
//             <div className="mb-6 relative">
//               <label
//                 htmlFor="password"
//                 className="block text-gray-600 text-sm mb-1"
//               >
//                 Password
//               </label>
//               <input
//                 type={showPassword ? "text" : "password"}
//                 id="password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//                 className="w-full border border-gray-300  ps-2 pe-10 py-1 text-primary  rounded focus:outline-none focus:ring-1 focus:ring-secondary focus:border-transparent"
//                 placeholder="********"
//                 required
//               />
//               {error && (
//                 <div className="-mb-6 text-red-600 text-sm">{error}</div>
//               )}
//               <button
//                 type="button"
//                 onClick={togglePasswordVisibility}
//                 className="absolute top-10 right-2 transform -translate-y-1/2 focus:outline-none"
//               >
//                 {showPassword ? (
//                   <FaEye
//                     className="text-secondary w-6 h-6"
//                     title="Hide Password"
//                   />
//                 ) : (
//                   <FaEyeSlash
//                     className="text-gray-400 w-6 h-6"
//                     title="Show Password"
//                   />
//                 )}
//               </button>
//             </div>
//             <div className="flex justify-between items-center mb-6">
//               <div className="flex items-center">
//                 <input
//                   id="link-checkbox"
//                   type="checkbox"
//                   value=""
//                   class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded  focus:ring-secondary  focus:ring-1  cursor-pointer"
//                 />
//                 <label className="ms-2 text-gray-600 text-sm">
//                   Remember me
//                 </label>
//               </div>
//               <button
//                 type="submit"
//                 className="custom-border bg-primary hover:bg-secondary px-10 py-1.5 text-white"
//                 disabled={isLoading}
//               >
//                 {isLoading ? "Logging In" : "Log In"}
//               </button>
//             </div>
//             <div className="text-primary text-sm cursor-pointer">
//               Having issues with your Password?
//             </div>
//           </form>
//         </div>
//       </div>
//       <div className="lg:w-[60%] lg:h-full px-2">
//         <img src={login} alt="" className="bg-cover lg:h-full w-full" />
//       </div>
//     </div>
//   );
// };

// export default Login;

// import React, { useState } from "react";
// import { registerUser, loginUser } from "../api/api"; // Import the API functions

// const Login = () => {
//   const [isRegistering, setIsRegistering] = useState(false);
//   const [formData, setFormData] = useState({
//     email: "",
//     password: "",
//   });
//   const [message, setMessage] = useState("");
//   const [loading, setLoading] = useState(false);

//   const toggleMode = () => {
//     setIsRegistering(!isRegistering);
//     setFormData({ email: "", password: "" });
//     setMessage("");
//   };

//   const handleChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     setMessage("");

//     try {
//       if (isRegistering) {
//         await registerUser(formData); // Call the register API function
//         setMessage("Registration successful. Please log in.");
//       } else {
//         const response = await loginUser(formData); // Call the login API function
//         localStorage.setItem("token", response.token); // Save the token
//         setMessage("Login successful!");
//       }
//     } catch (error) {
//       const errorMsg =
//         error.response?.data?.message || "An error occurred. Try again.";
//       setMessage(errorMsg);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
//       <h2 className="text-2xl font-semibold text-center mb-4">
//         {isRegistering ? "Register" : "Login"}
//       </h2>
//       <form onSubmit={handleSubmit}>
//         {isRegistering && (
//           <div className="mb-4">
//             <label
//               htmlFor="name"
//               className="block text-sm font-medium text-gray-700"
//             >
//               Name
//             </label>
//             <input
//               type="text"
//               name="name"
//               id="name"
//               value={formData.name}
//               onChange={handleChange}
//               className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
//               required
//             />
//           </div>
//         )}
//         <div className="mb-4">
//           <label
//             htmlFor="email"
//             className="block text-sm font-medium text-gray-700"
//           >
//             Email
//           </label>
//           <input
//             type="email"
//             name="email"
//             id="email"
//             value={formData.email}
//             onChange={handleChange}
//             className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label
//             htmlFor="password"
//             className="block text-sm font-medium text-gray-700"
//           >
//             Password
//           </label>
//           <input
//             type="password"
//             name="password"
//             id="password"
//             value={formData.password}
//             onChange={handleChange}
//             className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:outline-none"
//             required
//           />
//         </div>

//         <button
//           type="submit"
//           disabled={loading}
//           className="w-full px-4 py-2 mt-4 bg-primary text-white font-semibold rounded-md hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-primary"
//         >
//           {loading ? "Processing..." : isRegistering ? "Register" : "Login"}
//         </button>
//       </form>

//       {message && (
//         <p
//           className={`mt-4 text-center ${
//             message.includes("successful") ? "text-green-600" : "text-red-600"
//           }`}
//         >
//           {message}
//         </p>
//       )}

//       <button
//         onClick={toggleMode}
//         className="mt-4 text-primary text-sm font-medium underline mx-auto block"
//       >
//         {isRegistering
//           ? "Already have an account? Login"
//           : "Don't have an account? Register"}
//       </button>
//     </div>
//   );
// };

// export default Login;

import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import loginImage from "../../src/assets/login.png"; // Assuming you have a login image
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth"; // Import your custom hook for authentication

const Login = () => {
  const { login, error, user, loading } = useAuth(); // Get login function, error, and user state
  const [showPassword, setShowPassword] = useState(false); // Track password visibility
  const [email, setEmail] = useState(""); // Track email input
  const [password, setPassword] = useState(""); // Track password input
  const [isLoading, setIsLoading] = useState(false); // For loading state
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    // If user is already logged in, redirect to home or dashboard
    if (user) {
      navigate("/"); // Redirect to home page
    }
  }, [user, navigate]); // Run effect when user state changes

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle password visibility
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent form submission default behavior
    try {
      await login(email, password); // Call the login function from useAuth
    } catch (err) {
      console.error(err); // Log any error that occurs during login
    }
  };

  return (
    <div className="lg:h-screen bg-gray-100 lg:flex lg:justify-between items-center">
      {/* Left Section (Form) */}
      <div className="lg:w-[40%]">
        <div className="mx-10 mb-10 lg:mt-10  pt-10">
          <h1 className="lg:text-4xl text-3xl font-semibold">
            Welcome to Opt2Cash
          </h1>
          <p className="text-xs px-1 py-0.5 bg-secondary inline-block text-white rounded">
            Pharmacy
          </p>
          <form onSubmit={handleSubmit} className="my-5">
            {/* Email input */}
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-gray-600 text-sm mb-1"
              >
                Email or Username
              </label>
              <input
                type="text"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full border border-gray-300 px-2 py-1 text-primary rounded focus:outline-none focus:ring-1 focus:ring-secondary focus:border-transparent"
                placeholder="Enter Here"
                required
              />
              {error && (
                <div className="-mb-6 text-red-600 text-sm">{error}</div>
              )}
            </div>

            {/* Password input */}
            <div className="mb-6 relative">
              <label
                htmlFor="password"
                className="block text-gray-600 text-sm mb-1"
              >
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full border border-gray-300 ps-2 pe-10 py-1 text-primary rounded focus:outline-none focus:ring-1 focus:ring-secondary focus:border-transparent"
                placeholder="********"
                required
              />
              {error && (
                <div className="-mb-6 text-red-600 text-sm">{error}</div>
              )}
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute top-10 right-2 transform -translate-y-1/2 focus:outline-none"
              >
                {showPassword ? (
                  <FaEye
                    className="text-secondary w-6 h-6"
                    title="Hide Password"
                  />
                ) : (
                  <FaEyeSlash
                    className="text-gray-400 w-6 h-6"
                    title="Show Password"
                  />
                )}
              </button>
            </div>

            {/* Remember me & Login button */}
            <div className="flex justify-between items-center mb-6">
              <div className="flex items-center">
                <input
                  id="link-checkbox"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-secondary focus:ring-1 cursor-pointer"
                />
                <label className="ms-2 text-gray-600 text-sm">
                  Remember me
                </label>
              </div>
              <button
                type="submit"
                className="custom-border bg-primary hover:bg-secondary px-10 py-1.5 text-white"
                disabled={isLoading}
              >
                {isLoading ? "Logging In..." : "Log In"}
              </button>
            </div>

            {/* Forgot password link */}
            <div className="text-primary text-sm cursor-pointer">
              Having issues with your Password?
            </div>
          </form>
        </div>
      </div>

      {/* Right Section (Image) */}
      <div className="lg:w-[60%] lg:h-full px-2">
        <img
          src={loginImage}
          alt="Login illustration"
          className="bg-cover lg:h-full w-full"
        />
      </div>
    </div>
  );
};

export default Login;
