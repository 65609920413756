import { useState, useEffect } from "react";
import { loginUser, registerUser, logoutUser } from "../api/api"; // Import API functions

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [authToken, setAuthToken] = useState(localStorage.getItem("token")); // Store token in state

  useEffect(() => {
    // This useEffect will run on mount, and if token exists, it sets user
    if (authToken) {
      setLoading(false); // If the token is available, stop loading
    } else {
      setLoading(false); // No token, stop loading
    }
  }, [authToken]);

  // Login method using the API call
  const login = async (email, password) => {
    setLoading(true);
    setError("");

    try {
      const response = await loginUser({ email, password });
      setAuthToken(response.token); // Save token to state and localStorage
      localStorage.setItem("token", response.token); // Save token in localStorage
      setUser(response.user); // Assuming the API returns user info in response.user
    } catch (err) {
      setError("Login failed. Please check your credentials.");
    } finally {
      setLoading(false);
    }
  };

  // Logout method using the API call
  const logout = () => {
    localStorage.removeItem("token"); // Remove the token from localStorage
    setAuthToken(null); // Clear the authToken state
    setUser(null);
    setLoading(false);
  };

  return {
    user,
    authToken,
    loading,
    error,
    login,
    logout,
  };
};

export default useAuth;
