// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Dashboard from "../pages/Dashboard/Dashboard";
// import Login from "../pages/Login";
// import NewCustomer from "../pages/Customer/NewCustomer";
// import CustomerList from "../pages/Customer/CustomerList";
// import NewSupplier from "../pages/Supplier/NewSupplier";
// import SupplierList from "../pages/Supplier/SupplierList";
// import AddMedicine from "../pages/Medicine/AddMedicine";
// import MedicineList from "../pages/Medicine/MedicineList";
// import AddUnit from "../pages/Medicine/AddUnit";
// import UnitList from "../pages/Medicine/UnitList";
// import AddType from "../pages/Medicine/AddType";
// import TypeList from "../pages/Medicine/TypeList";
// import LeafSetting from "../pages/Medicine/LeafSetting";
// import AddPurchase from "../pages/Purchase/AddPurchase";
// import PurchaseList from "../pages/Purchase/PurchaseList";
// import NewSale from "../pages/Sale/NewSale";
// import SalesList from "../pages/Sale/SalesList";
// import AddReturn from "../pages/Return/AddReturn";
// import SalesReturnList from "../pages/Return/SalesReturnList";
// import PurchaseReturnList from "../pages/Return/PurchaseReturnList";
// import WastageReturnList from "../pages/Return/WastageReturnList";
// import NewExpense from "../pages/Expense/NewExpense";
// import ExpenseList from "../pages/Expense/ExpenseList";
// import NewCategory from "../pages/Expense/NewCategory";
// import CategoryList from "../pages/Expense/CategoryList";
// import ProfitLossReport from "../pages/Report/ProfitLossReport";
// import PurchaseReport from "../pages/Report/PurchaseReport";
// import PurchaseReturnReport from "../pages/Report/PurchaseReturnReport";
// import SalesReport from "../pages/Report/SalesReport";
// import SalesReturnReport from "../pages/Report/SalesReturnReport";
// import ExpenseReport from "../pages/Report/ExpenseReport";
// import ExpiredItemsReport from "../pages/Report/ExpiredItemsReport";
// import StockReport from "../pages/Report/StockReport";
// import UserSalesReport from "../pages/Report/UserSalesReport";
// import ProductSalesReport from "../pages/Report/ProductSalesReport";
// import NewUser from "../pages/Users/NewUser";
// import UserList from "../pages/Users/UserList";
// import RoleList from "../pages/Users/RoleList";
// import SendSMS from "../pages/SMS/SendSMS";
// import SMSTemplate from "../pages/SMS/SMSTemplate";
// import SMSAPI from "../pages/SMS/SMSAPI";
// import CompanyProfile from "../pages/Settings/CompanyProfile";
// import SiteSettings from "../pages/Settings/SiteSettings";
// import TaxList from "../pages/Settings/TaxList";
// import UnitsList from "../pages/Settings/UnitsList";
// import PaymentTypeList from "../pages/Settings/PaymentTypeList";
// import CurrencyList from "../pages/Settings/CurrencyList";
// import Language from "../pages/Settings/Language";
// import ChangePassword from "../pages/Settings/ChangePassword";
// import DatabaseBackup from "../pages/Settings/DatabaseBackup";
// import Help from "../pages/Settings/Help";
// import EditCustomer from "../pages/Customer/EditCustomer";
// import { useEffect, useState } from "react";
// import Loader from "../components/Loader";
// import EditSupplier from "../pages/Supplier/EditSupplier";
// import EditMedicine from "../pages/Medicine/EditMedicine";
// import EditUnit from "../pages/Medicine/EditUnit";
// import EditType from "../pages/Medicine/EditType";
// import EditLeaf from "../pages/Medicine/EditLeaf";
// import EditPurchase from "../pages/Purchase/EditPurchase";

// const AppRoutes = () => {
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     // Simulate a loading delay (or you can replace this with actual data loading)
//     setTimeout(() => {
//       setLoading(false); // Set loading to false after the loading finishes
//     }, 2000); // Adjust the delay as needed
//   }, []);

//   if (loading) {
//     return <Loader />; // Show the loader while loading
//   }

//   return (
//     <Router>
//       <Routes>
//         <Route path="/login" element={<Login />} />
//         <Route path="/" element={<Dashboard />} />
//         {/* customer management */}
//         <Route path="/newcustomer" element={<NewCustomer />} />
//         <Route path="/editcustomer/:id" element={<EditCustomer />} />
//         <Route path="/customerlist" element={<CustomerList />} />
//         {/* supplier management */}
//         <Route path="/newsupplier" element={<NewSupplier />} />
//         <Route path="/editsupplier/:id" element={<EditSupplier />} />
//         <Route path="/supplierlist" element={<SupplierList />} />
//         {/* medicine management */}
//         <Route path="/addmedicine" element={<AddMedicine />} />
//         <Route path="/editmedicine/:id" element={<EditMedicine />} />
//         <Route path="/medicinelist" element={<MedicineList />} />
//         <Route path="/addunit" element={<AddUnit />} />
//         <Route path="/editunit/:id" element={<EditUnit />} />
//         <Route path="/unitlist" element={<UnitList />} />
//         <Route path="/addtype" element={<AddType />} />
//         <Route path="/edittype/:id" element={<EditType />} />
//         <Route path="/typelist" element={<TypeList />} />
//         <Route path="/leafsetting" element={<LeafSetting />} />
//         <Route path="/editleaf/:id" element={<EditLeaf />} />
//         {/* purchase management */}
//         <Route path="/addpurchase" element={<AddPurchase />} />
//         <Route path="/editpurchase/:id" element={<EditPurchase />} />
//         <Route path="/purchaselist" element={<PurchaseList />} />
//         <Route path="/newsale" element={<NewSale />} />
//         <Route path="/saleslist" element={<SalesList />} />
//         <Route path="/addreturn" element={<AddReturn />} />
//         <Route path="/salesreturnlist" element={<SalesReturnList />} />
//         <Route path="/purchasereturnlist" element={<PurchaseReturnList />} />
//         <Route path="/wastagereturnlist" element={<WastageReturnList />} />
//         <Route path="/newexpense" element={<NewExpense />} />
//         <Route path="/expenselist" element={<ExpenseList />} />
//         <Route path="/newcategory" element={<NewCategory />} />
//         <Route path="/categorylist" element={<CategoryList />} />
//         <Route path="/profitlossreport" element={<ProfitLossReport />} />
//         <Route path="/purchasereport" element={<PurchaseReport />} />
//         <Route
//           path="/purchasereturnreport"
//           element={<PurchaseReturnReport />}
//         />
//         <Route path="/salesreport" element={<SalesReport />} />
//         <Route path="/salesreturnreport" element={<SalesReturnReport />} />
//         <Route path="/expensereport" element={<ExpenseReport />} />
//         <Route path="/expireditemsreport" element={<ExpiredItemsReport />} />
//         <Route path="/stockreport" element={<StockReport />} />
//         <Route path="/usersalesreport" element={<UserSalesReport />} />
//         <Route path="/productsalesreport" element={<ProductSalesReport />} />
//         <Route path="/newuser" element={<NewUser />} />
//         <Route path="/userlist" element={<UserList />} />
//         <Route path="/rolelist" element={<RoleList />} />
//         <Route path="/sendsms" element={<SendSMS />} />
//         <Route path="/smstemplate" element={<SMSTemplate />} />
//         <Route path="/smsapi" element={<SMSAPI />} />
//         <Route path="/companyprofile" element={<CompanyProfile />} />
//         <Route path="/sitesettings" element={<SiteSettings />} />
//         <Route path="/taxlist" element={<TaxList />} />
//         <Route path="/unitslist" element={<UnitsList />} />
//         <Route path="/paymenttypelist" element={<PaymentTypeList />} />
//         <Route path="/currencylist" element={<CurrencyList />} />
//         <Route path="/language" element={<Language />} />
//         <Route path="/changepassword" element={<ChangePassword />} />
//         <Route path="/databasebackup" element={<DatabaseBackup />} />
//         <Route path="/help" element={<Help />} />
//       </Routes>
//     </Router>
//   );
// };
// export default AppRoutes;

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import useAuth from "../hooks/useAuth"; // Import the useAuth hook
import Loader from "../components/Loader";

// Import Pages
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard/Dashboard";
import NewCustomer from "../pages/Customer/NewCustomer";
import CustomerList from "../pages/Customer/CustomerList";
import NewSupplier from "../pages/Supplier/NewSupplier";
import SupplierList from "../pages/Supplier/SupplierList";
import AddMedicine from "../pages/Medicine/AddMedicine";
import MedicineList from "../pages/Medicine/MedicineList";
import AddUnit from "../pages/Medicine/AddUnit";
import UnitList from "../pages/Medicine/UnitList";
import AddType from "../pages/Medicine/AddType";
import TypeList from "../pages/Medicine/TypeList";
import LeafSetting from "../pages/Medicine/LeafSetting";
import AddPurchase from "../pages/Purchase/AddPurchase";
import PurchaseList from "../pages/Purchase/PurchaseList";
import NewSale from "../pages/Sale/NewSale";
import SalesList from "../pages/Sale/SalesList";
import AddReturn from "../pages/Return/AddReturn";
import SalesReturnList from "../pages/Return/SalesReturnList";
import PurchaseReturnList from "../pages/Return/PurchaseReturnList";
import WastageReturnList from "../pages/Return/WastageReturnList";
import NewExpense from "../pages/Expense/NewExpense";
import ExpenseList from "../pages/Expense/ExpenseList";
import NewCategory from "../pages/Expense/NewCategory";
import CategoryList from "../pages/Expense/CategoryList";
import ProfitLossReport from "../pages/Report/ProfitLossReport";
import PurchaseReport from "../pages/Report/PurchaseReport";
import PurchaseReturnReport from "../pages/Report/PurchaseReturnReport";
import SalesReport from "../pages/Report/SalesReport";
import SalesReturnReport from "../pages/Report/SalesReturnReport";
import ExpenseReport from "../pages/Report/ExpenseReport";
import ExpiredItemsReport from "../pages/Report/ExpiredItemsReport";
import StockReport from "../pages/Report/StockReport";
import UserSalesReport from "../pages/Report/UserSalesReport";
import ProductSalesReport from "../pages/Report/ProductSalesReport";
import NewUser from "../pages/Users/NewUser";
import UserList from "../pages/Users/UserList";
import RoleList from "../pages/Users/RoleList";
import SendSMS from "../pages/SMS/SendSMS";
import SMSTemplate from "../pages/SMS/SMSTemplate";
import SMSAPI from "../pages/SMS/SMSAPI";
import CompanyProfile from "../pages/Settings/CompanyProfile";
import SiteSettings from "../pages/Settings/SiteSettings";
import TaxList from "../pages/Settings/TaxList";
import UnitsList from "../pages/Settings/UnitsList";
import PaymentTypeList from "../pages/Settings/PaymentTypeList";
import CurrencyList from "../pages/Settings/CurrencyList";
import Language from "../pages/Settings/Language";
import ChangePassword from "../pages/Settings/ChangePassword";
import DatabaseBackup from "../pages/Settings/DatabaseBackup";
import Help from "../pages/Settings/Help";
import EditCustomer from "../pages/Customer/EditCustomer";
import EditSupplier from "../pages/Supplier/EditSupplier";
import EditMedicine from "../pages/Medicine/EditMedicine";
import EditUnit from "../pages/Medicine/EditUnit";
import EditType from "../pages/Medicine/EditType";
import EditLeaf from "../pages/Medicine/EditLeaf";
import EditPurchase from "../pages/Purchase/EditPurchase";
import ViewPurchase from "../pages/Purchase/ViewPurchase";

const AppRoutes = () => {
  // const { authToken, user } = useAuth(); // Get authToken from context
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false); // Set loading to false after the loading finishes
  //   }, 2000); // Adjust the delay as needed
  // }, []);

  // if (loading) {
  //   return <Loader />; // Show loader while loading
  // }

  const { authToken, loading } = useAuth(); // Get authToken and loading state

  if (loading) {
    return <Loader />; // Show loading spinner while checking token or loading resources
  }

  const ProtectedRoute = ({ children }) => {
    // const token = localStorage.getItem("token"); // Check if the token is stored in localStorage

    if (!authToken) {
      return <Navigate to="/login" replace />; // If no token, redirect to login page
    }

    return children; // If the user is authenticated, render the children (protected content)
  };
  return (
    <Router>
      <Routes>
        {/* Public Route */}
        <Route path="/login" element={<Login />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newcustomer"
          element={
            <ProtectedRoute>
              <NewCustomer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editcustomer/:id"
          element={
            <ProtectedRoute>
              <EditCustomer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/customerlist"
          element={
            <ProtectedRoute>
              <CustomerList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newsupplier"
          element={
            <ProtectedRoute>
              <NewSupplier />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editsupplier/:id"
          element={
            <ProtectedRoute>
              <EditSupplier />
            </ProtectedRoute>
          }
        />
        <Route
          path="/supplierlist"
          element={
            <ProtectedRoute>
              <SupplierList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addmedicine"
          element={
            <ProtectedRoute>
              <AddMedicine />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editmedicine/:id"
          element={
            <ProtectedRoute>
              <EditMedicine />
            </ProtectedRoute>
          }
        />
        <Route
          path="/medicinelist"
          element={
            <ProtectedRoute>
              <MedicineList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addunit"
          element={
            <ProtectedRoute>
              <AddUnit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editunit/:id"
          element={
            <ProtectedRoute>
              <EditUnit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/unitlist"
          element={
            <ProtectedRoute>
              <UnitList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addtype"
          element={
            <ProtectedRoute>
              <AddType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edittype/:id"
          element={
            <ProtectedRoute>
              <EditType />
            </ProtectedRoute>
          }
        />
        <Route
          path="/typelist"
          element={
            <ProtectedRoute>
              <TypeList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/leafsetting"
          element={
            <ProtectedRoute>
              <LeafSetting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editleaf/:id"
          element={
            <ProtectedRoute>
              <EditLeaf />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addpurchase"
          element={
            <ProtectedRoute>
              <AddPurchase />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewpurchase/:id"
          element={
            <ProtectedRoute>
              <ViewPurchase />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editpurchase/:id"
          element={
            <ProtectedRoute>
              <EditPurchase />
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchaselist"
          element={
            <ProtectedRoute>
              <PurchaseList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newsale"
          element={
            <ProtectedRoute>
              <NewSale />
            </ProtectedRoute>
          }
        />
        <Route
          path="/saleslist"
          element={
            <ProtectedRoute>
              <SalesList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addreturn"
          element={
            <ProtectedRoute>
              <AddReturn />
            </ProtectedRoute>
          }
        />
        <Route
          path="/salesreturnlist"
          element={
            <ProtectedRoute>
              <SalesReturnList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchasereturnlist"
          element={
            <ProtectedRoute>
              <PurchaseReturnList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wastagereturnlist"
          element={
            <ProtectedRoute>
              <WastageReturnList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newexpense"
          element={
            <ProtectedRoute>
              <NewExpense />
            </ProtectedRoute>
          }
        />
        <Route
          path="/expenselist"
          element={
            <ProtectedRoute>
              <ExpenseList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newcategory"
          element={
            <ProtectedRoute>
              <NewCategory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/categorylist"
          element={
            <ProtectedRoute>
              <CategoryList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profitlossreport"
          element={
            <ProtectedRoute>
              <ProfitLossReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchasereport"
          element={
            <ProtectedRoute>
              <PurchaseReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchasereturnreport"
          element={
            <ProtectedRoute>
              <PurchaseReturnReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/salesreport"
          element={
            <ProtectedRoute>
              <SalesReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/salesreturnreport"
          element={
            <ProtectedRoute>
              <SalesReturnReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/expensereport"
          element={
            <ProtectedRoute>
              <ExpenseReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/expireditemsreport"
          element={
            <ProtectedRoute>
              <ExpiredItemsReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/stockreport"
          element={
            <ProtectedRoute>
              <StockReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/usersalesreport"
          element={
            <ProtectedRoute>
              <UserSalesReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/productsalesreport"
          element={
            <ProtectedRoute>
              <ProductSalesReport />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newuser"
          element={
            <ProtectedRoute>
              <NewUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/userlist"
          element={
            <ProtectedRoute>
              <UserList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rolelist"
          element={
            <ProtectedRoute>
              <RoleList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sendsms"
          element={
            <ProtectedRoute>
              <SendSMS />
            </ProtectedRoute>
          }
        />
        <Route
          path="/smstemplate"
          element={
            <ProtectedRoute>
              <SMSTemplate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/smsapi"
          element={
            <ProtectedRoute>
              <SMSAPI />
            </ProtectedRoute>
          }
        />
        <Route
          path="/companyprofile"
          element={
            <ProtectedRoute>
              <CompanyProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/sitesettings"
          element={
            <ProtectedRoute>
              <SiteSettings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/taxlist"
          element={
            <ProtectedRoute>
              <TaxList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/unitslist"
          element={
            <ProtectedRoute>
              <UnitsList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/paymenttypelist"
          element={
            <ProtectedRoute>
              <PaymentTypeList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/currencylist"
          element={
            <ProtectedRoute>
              <CurrencyList />
            </ProtectedRoute>
          }
        />
        <Route
          path="/language"
          element={
            <ProtectedRoute>
              <Language />
            </ProtectedRoute>
          }
        />
        <Route
          path="/changepassword"
          element={
            <ProtectedRoute>
              <ChangePassword />
            </ProtectedRoute>
          }
        />
        <Route
          path="/databasebackup"
          element={
            <ProtectedRoute>
              <DatabaseBackup />
            </ProtectedRoute>
          }
        />
        <Route
          path="/help"
          element={
            <ProtectedRoute>
              <Help />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
