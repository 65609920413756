import React, { useEffect, useRef, useState } from "react";
import { FaBars } from "react-icons/fa6";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import Select from "react-select";
import Swal from "sweetalert2";
import { FaUserPlus } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { IoCloseCircleSharp } from "react-icons/io5";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchSuppliers,
  fetchLeafs,
  fetchMedicinesBySupplier,
  editPurchase,
} from "../../api/api";
import DatePicker from "react-datepicker"; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import CSS for DatePicker
import { format, addDays, getYear, getMonth } from "date-fns"; // Import getYear and getMonth from date-fns

// Helper function for generating year range
const generateYearRange = (start, end, step) => {
  let range = [];
  for (let i = start; i < end; i += step) {
    range.push(i);
  }
  return range;
};

const EditPurchase = () => {
  useEffect(() => {
    document.title = "Add Purchase";
  }, []);

  // const [startDate, setStartDate] = useState(new Date());
  const years = generateYearRange(2020, 2041, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [suppliers, setSuppliers] = useState([]);
  const [medicines, setMedicines] = useState([]);
  const [leafPatterns, setLeafPatterns] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const { purchase } = location.state || {};

  const [formData, setFormData] = useState({
    supplierId: "",
    invoiceNo: "",
    date: new Date(),
    details: "",
    paymentType: "Cash",
    subTotal: 0,
    vat: 0,
    discount: 0,
    grandTotal: 0,
    paidAmount: 0,
    dueAmount: 0,
    medicineRows: [],
  });

  const [isInitialized, setIsInitialized] = useState(false);

  // Handle `useEffect` to update form data based on `purchase`
  useEffect(() => {
    if (purchase && !isInitialized) {
      setFormData({
        purchaseId: purchase.id || "",
        supplierId: purchase.supplier_id || "",
        invoiceNo: purchase.invoice_no || "",
        date: new Date(purchase.date) || new Date(),
        details: purchase.details || "",
        paymentType: purchase.payment_type || "Cash",
        subTotal: purchase.sub_total || 0,
        vat: purchase.vat || 0,
        discount: purchase.discount || 0,
        grandTotal: purchase.grand_total || 0,
        paidAmount: purchase.paid_amount || 0,
        dueAmount: purchase.due_amount || 0,
        medicineRows: purchase.purchase_items.map((item) => ({
          medicineId: item.medicine_id || "",
          batchId: item.batch_id || "",
          expireDate: new Date(item.expire_date) || addDays(new Date(), 1),
          stockQty: item.stock_qty || "",
          boxPattern: item.box_pattern || "",
          boxQty: item.box_qty || 0,
          quantity: item.quantity || 0,
          supplierPrice: item.supplier_price || "",
          boxMRP: item.box_mrp || "",
          totalPurchasePrice: item.total_purchase_price || 0,
          medicineName: "", // Placeholder for the medicine name
        })),
      });
      setIsInitialized(true); // Mark as initialized after setting form data
    }
  }, [purchase, isInitialized]);

  // Update medicine names whenever the `medicines` array changes
  useEffect(() => {
    if (medicines.length > 0) {
      setFormData((prevState) => ({
        ...prevState,
        medicineRows: prevState.medicineRows.map((row) => {
          const medicine = medicines.find((med) => med.id === row.medicineId);
          return {
            ...row,
            medicineName: medicine
              ? `${medicine.name} (${medicine.strength})`
              : "", // Set medicine name if found
          };
        }),
      }));
    }
  }, [medicines]);

  const [expiryDateError, setExpiryDateError] = useState("");
  const [popupIndex, setPopupIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const popupRef = useRef(null);

  useEffect(() => {
    // Fetch suppliers and leaf patterns when the component mounts
    fetchSuppliers()
      .then((data) => setSuppliers(data))
      .catch((error) => console.error("Error fetching suppliers:", error));

    fetchLeafs()
      .then((data) => {
        const updatedLeafPatterns = data.map((pattern) => ({
          label: `${pattern.type} (${pattern.total_number})`,
          value: pattern.total_number,
          totalNumber: pattern.total_number,
        }));
        setLeafPatterns(updatedLeafPatterns);
      })
      .catch((error) => console.error("Error fetching leaf patterns:", error));
  }, []);

  useEffect(() => {
    // Fetch medicines based on selected supplier ID
    if (formData.supplierId) {
      fetchMedicinesBySupplier(formData.supplierId)
        .then((data) => setMedicines(data))
        .catch((error) => console.error("Error fetching medicines:", error));
    } else {
      setMedicines([]);
    }
  }, [formData.supplierId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => {
      const updatedFormData = { ...prevState, [name]: value };
      calculateTotal(updatedFormData);
      return updatedFormData;
    });
    setTimeout(() => {
      calculateTotal({
        ...formData,
        [name]: value, // Use the updated value directly
      });
    }, 0);
  };

  const handleRowChange = (index, field, value) => {
    // Create a deep copy of the medicineRows to maintain immutability
    const updatedRows = [...formData.medicineRows];
    updatedRows[index] = { ...updatedRows[index], [field]: value }; // Update the specific field immutably

    if (field === "expireDate") {
      const purchaseDate = formData.date;
      const selectedExpireDate = new Date(value);

      // Check if expiry date is greater than purchase date
      if (selectedExpireDate <= purchaseDate) {
        toast.error("Expiry date must be later than the purchase date.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        updatedRows[index].expireDate = addDays(purchaseDate, 1); // Reset the invalid expiry date
      } else {
        setExpiryDateError(""); // Clear the error if valid
        updatedRows[index].expireDate = selectedExpireDate;
      }
    }

    // Handle medicineId change and set the supplier price
    if (field === "medicineId") {
      const selectedMedicine = medicines.find(
        (med) => med.id === parseInt(value)
      );
      if (selectedMedicine) {
        updatedRows[index].supplierPrice = selectedMedicine.supplier_price || 0;

        // Update the total purchase price based on the selected medicine's supplier price
        updatedRows[index].totalPurchasePrice =
          (updatedRows[index].boxQty || 0) * selectedMedicine.supplier_price;
        updatedRows[index].quantity = updatedRows[index].quantity || 0;
      }
    }

    // Handle boxPattern selection and update the patternMultiplier
    if (field === "boxPattern") {
      const selectedPattern = leafPatterns.find(
        (pattern) => pattern.value === parseInt(value)
      );
      if (selectedPattern) {
        updatedRows[index].patternMultiplier = selectedPattern.totalNumber || 0;
        updatedRows[index].quantity =
          (updatedRows[index].boxQty || 0) * (selectedPattern.totalNumber || 0);
      }
    }

    // Handle boxQty change and recalculate quantity and total purchase price
    if (field === "quantity") {
      updatedRows[index].quantity = parseInt(value) || 0;

      // Optionally update total purchase price
      updatedRows[index].totalPurchasePrice =
        updatedRows[index].quantity *
        (parseFloat(updatedRows[index].supplierPrice) || 0);
    }

    // Recalculate total purchase price when quantity or supplier price changes
    if (["boxQty", "supplierPrice"].includes(field)) {
      // Recalculate quantity if a patternMultiplier exists
      if (updatedRows[index].patternMultiplier) {
        updatedRows[index].quantity =
          (updatedRows[index].boxQty || 0) *
          (updatedRows[index].patternMultiplier || 0);
      }

      // Update total purchase price
      updatedRows[index].totalPurchasePrice =
        (updatedRows[index].boxQty || 0) *
        (parseFloat(updatedRows[index].supplierPrice) || 0);
    }

    // Update the formData state with recalculated values
    setFormData((prevState) => {
      const updatedFormData = {
        ...prevState,
        medicineRows: updatedRows,
      };

      let subTotal = 0;
      updatedFormData.medicineRows.forEach((row) => {
        subTotal += row.totalPurchasePrice || 0; // Accumulate `totalPurchasePrice` for subtotal
      });

      // Update the state with recalculated totals
      return {
        ...updatedFormData,
        subTotal, // Updated subtotal
        grandTotal:
          subTotal +
          (parseFloat(updatedFormData.vat) || 0) -
          (parseFloat(updatedFormData.discount) || 0),
        dueAmount:
          subTotal +
          (parseFloat(updatedFormData.vat) || 0) -
          (parseFloat(updatedFormData.discount) || 0) -
          (parseFloat(updatedFormData.paidAmount) || 0),
      };
    });
  };

  const calculateTotal = (updatedFormData) => {
    let subTotal = 0;

    // Calculate subtotal from medicine rows
    updatedFormData.medicineRows.forEach((row) => {
      subTotal += row.totalPurchasePrice || 0;
    });

    const vat = parseFloat(updatedFormData.vat) || 0; // Ensure VAT is treated as a number
    const discount = parseFloat(updatedFormData.discount) || 0;
    const paidAmount = parseFloat(updatedFormData.paidAmount) || 0;

    // Grand Total calculation: Subtotal + VAT - Discount
    const grandTotal = subTotal + vat - discount;
    const dueAmount = grandTotal - paidAmount;

    setFormData((prevState) => ({
      ...prevState,
      subTotal,
      grandTotal,
      dueAmount,
    }));
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const openPopup = (index) => {
    setPopupIndex(index);
  };

  const closePopup = () => {
    setPopupIndex(null);
    setSearchTerm("");
  };

  const handleMedicineSelect = (medicine) => {
    const updatedRows = [...formData.medicineRows];
    updatedRows[popupIndex].medicineId = medicine.id;
    updatedRows[popupIndex].medicineName = medicine.name;
    updatedRows[
      popupIndex
    ].medicineName = `${medicine.name} (${medicine.strength})`;
    updatedRows[popupIndex].supplierPrice = medicine.supplier_price || 0;
    updatedRows[popupIndex].boxMRP = medicine.price || 0;
    updatedRows[popupIndex].totalPurchasePrice =
      (updatedRows[popupIndex].quantity || 0) * (medicine.supplier_price || 0);
    setFormData((prevState) => ({
      ...prevState,
      medicineRows: updatedRows,
    }));
    closePopup();
  };

  const filteredMedicines = medicines.filter((medicine) =>
    medicine.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = async () => {
    const payload = {
      ...formData,
      date: format(formData.date, "yyyy-MM-dd"),
      medicineRows: formData.medicineRows.map((row) => ({
        ...row,
        expireDate: format(row.expireDate, "yyyy-MM-dd"),
      })),
    };

    try {
      await editPurchase(purchase.id, payload);
      Swal.fire({
        title: "Success!",
        text: "Purchase updated successfully!",
        icon: "success",
        confirmButtonText: "OK",
      });
    } catch (error) {
      console.error("Error updating purchase:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to update purchase. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <DashboardLayout>
      <div className="p-4 bg-white custom-shadow custom-border w-full">
        <div className="mb-5 flex justify-between items-center">
          <h2 className="text-lg font-semibold text-primary ">Edit Purchase</h2>
          <div>
            <Link to="/purchaselist">
              <p className="flex items-center gap-2 px-4 py-2 bg-secondary text-white custom-border cursor-pointer">
                <span>
                  <FaBars />
                </span>
                Purchase List
              </p>
            </Link>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 ">
          <div class="flex justify-between items-end gap-2">
            <div className="flex flex-col w-full">
              <label for="supplier" class="text-primary font-semibold mb-1">
                Supplier*
              </label>
              <input
                name="supplierId"
                value={
                  formData.supplierId
                    ? suppliers
                        .filter(
                          (supplier) =>
                            supplier.id === parseInt(formData.supplierId) &&
                            supplier.status === 1 // Ensure supplier is active
                        )
                        .map(
                          (supplier) => `${supplier.name} (${supplier.mobile})`
                        )[0] || "Inactive Supplier" // Fallback if not found or inactive
                    : ""
                }
                className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
                readOnly
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Date*</label>
            <DatePicker
              className="text-primary relative px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none w-full"
              selected={formData.date}
              onChange={(date) => setFormData({ ...formData, date })}
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div className="flex justify-self-center items-center gap-3 py-3">
                  <button
                    type="button"
                    className="bg-secondary p-1 rounded text-white hover:bg-primary font-semibold"
                    onClick={decreaseMonth}
                    disabled={prevMonthButtonDisabled}
                  >
                    <FaArrowLeft />
                  </button>
                  <select
                    className="p-1 border  focus:outline-none"
                    value={getMonth(date)}
                    onChange={({ target: { value } }) => changeMonth(value)}
                  >
                    {months.map((month, index) => (
                      <option key={index} value={index}>
                        {month}
                      </option>
                    ))}
                  </select>

                  <select
                    className="p-1 border focus:outline-none"
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {years.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>

                  <button
                    type="button"
                    className="bg-secondary p-1 rounded text-white hover:bg-primary font-semibold"
                    onClick={increaseMonth}
                    disabled={nextMonthButtonDisabled}
                  >
                    <FaArrowRight />
                  </button>
                </div>
              )}
            />
          </div>

          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">
              Invoice No*
            </label>
            <input
              type="text"
              name="invoiceNo"
              value={formData.invoiceNo}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
              required
              maxLength={20}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-primary font-semibold mb-1">Details</label>
            <input
              type="text"
              name="details"
              value={formData.details}
              onChange={handleInputChange}
              placeholder="Enter Here"
              className="px-2 py-1.5 border border-gray-300 rounded-lg focus:ring-1 focus:ring-secondary focus:outline-none "
            />
          </div>

          <div class="flex ">
            <div className="flex flex-col w-full">
              <label for="type" class="text-primary font-semibold mb-1">
                Payment Type*
              </label>
              <Select
                id="paymentType"
                name="paymentType"
                value={{
                  value: formData.paymentType,
                  label: formData.paymentType, // Ensure label matches the selected value
                }}
                onChange={(selectedOption) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    paymentType: selectedOption.value, // Update state with the selected value
                  }))
                }
                options={[
                  { value: "Cash", label: "Cash" }, // Option 1
                  { value: "Bank", label: "Bank" }, // Option 2
                ]}
                placeholder="Select Payment Type"
                isSearchable
                styles={{
                  control: (base, state) => ({
                    ...base,
                    borderRadius: "8px",
                    boxShadow: state.isFocused ? "0 0 0 0.5px #0090FF" : null,
                  }),
                  dropdownIndicator: (base) => ({
                    ...base,
                    color: "#0090FF",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    color: "#A0AEC0",
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused ? "#0090FF" : "#fff",
                    color: state.isFocused ? "#000" : "#333",
                    cursor: "pointer",
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: "#333",
                  }),
                }}
              />
            </div>
          </div>
        </div>

        <table className=" mt-10 min-w-full ">
          <thead>
            <tr className=" bg-primary text-center text-white font-semibold text-sm">
              <th className="px-1 py-2 border-r">Medicine Name*</th>
              <th className="px-1 py-2 border-r">Batch No</th>
              <th className="px-1 py-2 border-r">Expiry Date*</th>
              <th className="px-1 py-2 border-r">Stock Qty</th>
              <th className="px-1 py-2 border-r">Box Pattern*</th>
              <th className="px-1 py-2 border-r">Box Qty*</th>
              <th className="px-1 py-2 border-r">Quantity*</th>
              <th className="px-1 py-2 border-r">Supplier Price*</th>
              <th className="px-1 py-2 border-r">Box MRP*</th>
              <th className="px-1 py-2 border-r">Total Purchase Price</th>
            </tr>
          </thead>

          <tbody className="">
            {formData.medicineRows.map((row, index) => (
              <tr key={index} className="bg-white text-center text-sm">
                <td className="relative">
                  <div
                    onClick={() => openPopup(index)}
                    className="border border-primary text-primary rounded px-1 py-1 truncate w-24 cursor-pointer"
                  >
                    {row.medicineName || "Enter Here"}
                  </div>
                  {popupIndex === index && (
                    <div className="absolute left-0 mt-2 z-10" ref={popupRef}>
                      <div className="bg-white custom-shadow rounded-lg w-64 p-3">
                        <div className="flex justify-between items-center gap-2 mb-2">
                          <input
                            type="text"
                            placeholder="Search Medicine"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="w-full px-2 py-1.5 border border-gray-300 rounded focus:ring-1 focus:ring-secondary focus:outline-none"
                          />
                          <button
                            onClick={closePopup}
                            className="text-primary hover:text-red-500 text-2xl"
                          >
                            <span>
                              <IoCloseCircleSharp />
                            </span>
                          </button>
                        </div>
                        <ul className="space-y-2 max-h-40 overflow-y-auto">
                          {filteredMedicines.length > 0 ? (
                            filteredMedicines.map((medicine) => (
                              <li
                                key={medicine.id}
                                onClick={() => handleMedicineSelect(medicine)}
                                className="px-2 py-1.5 rounded cursor-pointer hover:bg-secondary hover:text-white bg-gray-300 text-primary"
                              >
                                {medicine.name} ({medicine.strength})
                              </li>
                            ))
                          ) : (
                            <li className="px-2 py-1.5 rounded bg-gray-300 text-red-500">
                              No medicine found
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="text"
                    placeholder="Enter Here"
                    className="border border-primary text-primary rounded w-full px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none"
                    value={row.batchId}
                    onChange={(e) =>
                      handleRowChange(index, "batchId", e.target.value)
                    }
                  />
                </td>
                <td className="py-2 px-0.5">
                  <DatePicker
                    className="border border-primary text-primary rounded w-full px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none"
                    selected={row.expireDate}
                    onChange={(date) =>
                      handleRowChange(index, "expireDate", date)
                    }
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div className="flex justify-self-center items-center gap-3 py-3">
                        <button
                          type="button"
                          className="bg-secondary p-1 rounded text-white hover:bg-primary font-semibold"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          <FaArrowLeft />
                        </button>
                        <select
                          className="p-1 border  focus:outline-none"
                          value={getMonth(date)}
                          onChange={({ target: { value } }) =>
                            changeMonth(value)
                          }
                        >
                          {months.map((month, index) => (
                            <option key={index} value={index}>
                              {month}
                            </option>
                          ))}
                        </select>

                        <select
                          className="p-1 border focus:outline-none"
                          value={getYear(date)}
                          onChange={({ target: { value } }) =>
                            changeYear(value)
                          }
                        >
                          {years.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>

                        <button
                          type="button"
                          className="bg-secondary p-1 rounded text-white hover:bg-primary font-semibold"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          <FaArrowRight />
                        </button>
                      </div>
                    )}
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    readOnly
                    className="text-gray-500 bg-gray-300 border rounded w-full px-1 py-1 focus:outline-none"
                    value={row.stockQty}
                    onChange={(e) =>
                      handleRowChange(index, "stockQty", e.target.value)
                    }
                  />
                </td>
                <td className="py-2 px-0.5">
                  <select
                    name="boxPattern"
                    value={row.boxPattern || ""} // Default to an empty value if not set
                    onChange={(e) =>
                      handleRowChange(index, "boxPattern", e.target.value)
                    }
                    className="border border-primary text-primary rounded w-full px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none"
                  >
                    <option value="" disabled>
                      Leaf Type
                    </option>
                    {leafPatterns.map((pattern) => (
                      <option key={pattern.value} value={pattern.value}>
                        {pattern.label}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    className="border border-primary text-primary rounded w-full px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none"
                    value={row.boxQty}
                    onChange={(e) =>
                      handleRowChange(index, "boxQty", e.target.value)
                    }
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    readOnly
                    className="text-gray-500 bg-gray-300 border rounded w-full px-1 py-1 focus:outline-none"
                    value={row.quantity}
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    className="border border-primary text-primary rounded w-full px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none"
                    value={row.supplierPrice}
                    onChange={(e) =>
                      handleRowChange(index, "supplierPrice", e.target.value)
                    }
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    name="boxMRP"
                    placeholder="0.00"
                    className="border border-primary text-primary rounded w-full px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none"
                    value={row.boxMRP}
                    onChange={(e) =>
                      handleRowChange(index, "boxMRP", e.target.value)
                    }
                    required
                  />
                </td>
                <td className="py-2 px-0.5">
                  <input
                    type="number"
                    placeholder="0.00"
                    readOnly
                    className="text-gray-500 bg-gray-300 border rounded w-full px-1 py-1 focus:outline-none"
                    value={row.totalPurchasePrice}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-end w-full mt-2">
          <div className="custom-shadow custom-border p-4 px-8 text-center ">
            <div className="flex items-center justify-between mb-2 w-full gap-2">
              <p className="text-primary font-semibold">Sub Total :</p>
              <input
                type="number"
                name="subTotal"
                value={formData.subTotal || 0}
                readOnly
                className="border border-gray-300 text-primary rounded  px-1 py-1  bg-gray-300 focus:outline-none  text-end"
              />
            </div>
            <div className="flex items-center justify-between mb-2 w-full gap-2">
              <p className="text-primary font-semibold">Vat :</p>
              <input
                type="number"
                name="vat"
                placeholder="0.00"
                value={formData.vat}
                onChange={handleInputChange}
                className="border border-primary text-primary rounded  px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none text-end"
              />
            </div>
            <div className="flex items-center justify-between mb-2 w-full gap-2">
              <p className="text-primary font-semibold">Discount :</p>
              <input
                type="number"
                name="discount"
                placeholder="0.00"
                value={formData.discount}
                onChange={handleInputChange}
                className="border border-primary text-primary rounded  px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none text-end"
              />
            </div>
            <div className="flex items-center justify-between mb-2 w-full gap-2">
              <p className="text-primary font-semibold">Grand Total :</p>
              <input
                type="number"
                name="grandTotal"
                value={formData.grandTotal || 0}
                readOnly
                className="border border-gray-300 text-primary rounded  px-1 py-1  bg-gray-300 focus:outline-none  text-end"
              />
            </div>
            <div className="flex items-center justify-between mb-2 w-full gap-2">
              <p className="text-primary font-semibold">Paid Amount :</p>
              <input
                type="number"
                name="paidAmount"
                placeholder="0.00"
                value={formData.paidAmount}
                onChange={handleInputChange}
                className="border border-primary text-primary rounded  px-1 py-1 focus:ring-1 focus:ring-secondary focus:outline-none text-end"
              />
            </div>
            <div className="flex items-center justify-between w-full gap-2">
              <p className="text-primary font-semibold">Due Amount :</p>
              <input
                type="number"
                name="dueAmount"
                value={formData.dueAmount || 0}
                readOnly
                className="border border-gray-300 text-primary rounded  px-1 py-1  bg-gray-300 focus:outline-none  text-end"
              />
            </div>
            <div className="flex justify-between gap-3 mt-4">
              <div
                className="bg-secondary hover:text-primary font-semibold  py-2 rounded text-white w-full cursor-pointer"
                onClick={handleSubmit}
              >
                Full Paid
              </div>
              <div
                className="bg-primary hover:text-secondary font-semibold py-2 rounded text-white w-full cursor-pointer"
                onClick={handleSubmit}
              >
                Save
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </DashboardLayout>
  );
};

export default EditPurchase;
